@import "../../../../common/styles/mediaQueriesConstants";

.permissions-panel {
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
  overflow: auto;
}

.role-editor {
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: 56px 1fr;
  height: 100%;
}

.role-editor-title {
  padding-left: 10px;
}

.role-editor-form {
  grid-row: 2;
  grid-column: 1;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 6px 1px 6px -6px, rgba(0, 0, 0, 0.12) 0px 1px 4px -4px;
  position: relative;
  border-top: 1px solid #fafafa;
}

.role-editor-permissions {
  grid-row: 2;
  padding: 20px;
  overflow: auto;
  background-color: #fafafa;
}

@media #{$mobile-only} {
  .role-editor {
    grid-template-columns: 1fr;
  }

  .role-editor-permissions {
    grid-row: 3;
  }

  .role-editor-form {
    .role-form {
      width: 280px;
      margin: 0 auto;
    }
    .role-form-footer {
      margin-top: 20px;
    }
  }
}
