.charger-configuration {
  height: calc(100dvh - 137px);
  width: calc(100%);

  display: flex;
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.870588);

  .gutter {
    cursor: col-resize;
  }
}

#chargerConfiguration {
  height: 100%;
  overflow-y: auto;

  .group-description {
    height: 13px;
    transition: .2s;
    width: 13px;
    border-radius: 50%;
    background-color: #0000007a;
    margin-left: 4px;
    color: white;
    font-family: cursive;
    text-align: center;
    line-height: 13px;
    font-size: 10px;
    cursor: pointer;
    margin-bottom: 2px;

    &:hover {
      background-color: #000;
    }
  }

  .MuiPaper-elevation1 {
    margin-bottom: 15px;

    &.Mui-expanded {
      background-color: #e6e6e6;
    }
  }

  @media #{$mobile-only} {
    .MuiAccordionDetails-root {
      display: block;
      padding: 8px;
    }

    .charger-configuration-field {
      width: 100%;
      margin-right: unset;
      max-width: unset
    }

    .field-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .empty-configuration-message {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiCardContent-root {
    padding: unset;
  }
}

.empty-configuration-message {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  color: rgba(0, 0, 0, 0.53);
}

.charger-configuration-pane {
  padding-top: 20px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  background-color: white;
}

.charger-configuration-list-item {
  padding: 10px 20px;
  cursor: pointer;
}

.charger-configuration-list-item-selected,
.charger-configuration-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.charger-configuration-field-pane {
  padding: 12px 5px 5px 5px;

  &.dark-background {
    background-color: #f0f0f0;
  }
}

.charger-configuration-field {
  display: flex;
  align-items: center;

  .MuiButtonBase-root {
    height: 48px;
  }

  .Mui-disabled {
    color: grey !important;
  }

  .no-value {
    margin-left: 10px;
    font-style: italic;
    color: gray;
  }

  .MuiFormLabel-root {
    white-space: nowrap;
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
}

.charger-configuration-checkbox-field {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .MuiFormControl-root {
    display: unset;
  }
}

.charger-configuration-help-message {
  text-align: center;
  font-style: italic;
}

.parameters-divider-container {
  position: relative;
  margin-top: 13px;
  margin-bottom: 20px;

  hr {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }

  .parameters-group-name {
    position: absolute;
    top: -9px;
    left: 15px;
    background: #fff;
    padding: 0 5px 0 5px;
    display: flex;
    align-items: center;
  }
}

.field-row {
  display: flex;
  flex-direction: column;

  padding: 10px;
  margin-bottom: 15px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(240, 240, 240) 0%, white 89%, white 100%);
  background: linear-gradient(90deg, white 0%, white 50%, #f0f0f0 100%);

  .field-container {
    padding-left: 20px;

    display: flex;
    align-items: flex-end;

    .current-value {
      color: rgba(0, 0, 0, 0.60);
    }

    .label-marker-container {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      width: 200%;
    }

    .parameter-label-container {
      display: flex;
      flex-direction: column;
    }
  }

  .MuiInput-formControl {
    margin-top: unset;
  }

  .MuiInputLabel-shrink {
    transform: unset;
  }

  .MuiInputLabel-formControl {
    transform: unset;
    position: unset;
    white-space: unset;
  }
}

.package-field-description-container {
  display: flex;

  &.pointer {
    cursor: pointer;
  }

  .package-field-description {
    font-size: 11px;
    font-style: italic;
    overflow: hidden;
    max-height: 11px;
    margin-left: 5px;
    transition: max-height 0.3s ease-in-out;

    &.open {
      max-height: 800px;
    }
  }

  .package-field-description-icon {
    width: 11px;
    height: 11px;
    padding: 0;
    top: -3px;
    transform: rotate(0deg) scale(1.5);
    transition: .2s;

    &.open {
      top: 3px;
      transform: rotate(180deg) scale(1.5);
    }
  }
}

.configuration-parameters-toolbar {
  border-bottom: 1px solid #888888;
}

.group-header {
  display: flex;
  align-items: center;

  .package-field-description {
    margin-top: 5px;
  }
}

.updated-node-count-icon {
  color: white;
  background-color: #ef5257;
  min-width: 12px;
  height: 12px;
  border-radius: 9px;
  line-height: 13px;
  text-align: center;
  font-size: 11px;
  padding: 2px;
  cursor: pointer;
}

.modified-parameter-popup-row {
  border-bottom: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 10px;
}

.modified-params {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.modified-params-headline {
  display: flex;
  width: 100%;
  font-weight: bold;
  padding-bottom: 15px;
}

.modified-params-body {
  display: flex;
  width: 100%;
  padding: 20px 0 0 0;
  flex-direction: column;
}

.modified-params-row {
  display: flex;
  width: 100%;
  padding: 0 0 15px;
  margin: 0 0 15px;
  border-bottom: solid 1px #ccc;

  &:last-child {
    border: none
  }
}

.modified-params-cell {
  display: flex;
  word-break: break-all;
  flex: 1;
  gap: 10px;

  .parameter-status-marker {
    margin-top: 3px;
  }

  .MuiSvgIcon-root {
    margin: 0 10px 0 0;
    color: #999;
    cursor: pointer;
  }

  &:last-child {
    padding-right: 0;
  }
}

.parameter-status-marker {
  margin-right: 5px;
  margin-bottom: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &.LIVE {
    background-color: #9ec772;
  }

  &.MODIFIED {
    background-color: #728fc3;
  }

  &.REQUESTED {
    background-color: #fdc461;
  }

  &.REJECTED {
    background-color: #d96166;
  }

  &.APPLIED {
    background-color: #D4CBE5;
  }
}

.parameter-popup-value {
  color: #000000e6;
  padding: 0 5px;
  word-break: break-all;
}

.configuration-confirmation-meassage-underline {
  font-weight: bold;
  color: #000;
  margin-top: 20px;
}

.confirmation-dialog-text {
  &.is-fetching {
    filter: blur(4px);
  }
}
