@use "sass:math";
@import "../../common/styles/fonts";
@import "../../common/styles/mediaQueriesConstants";

.gmap-container {
  width: 100% !important;
  height: 100% !important;
}

.i2p2-map-block {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  & .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
      bottom: 115px !important;
      right: 55px !important;
      position: fixed !important;
  }

  &.list-mode .gmap-container {
    display: none;
  }

  &.list-mode {
    .map-controls {
      &__filter {
        display: flex;
        flex-direction: row-reverse;
        padding: 10px 30px;
        justify-content: flex-end;
        box-sizing: border-box;

        @media #{$mobile-portait-only} {
          padding: 0;
          flex-direction: column;
          align-items: center;
        }

        .advanced-search {
          .MuiButtonBase-root {
            border: none !important;
            border-radius: 2px;
            box-shadow: rgba(0, 0, 0, math.div(1, 8.5)) 0 1px 6px, rgba(0, 0, 0, math.div(1, 8.5)) 0 1px 4px;
          }

          @media #{$mobile-portait-only} {
            width: 100%;
            z-index: 1;

            > .MuiButtonGroup-root {
              display: flex;
    
              > .MuiButtonBase-root {
                display: flex;
                flex: 1;
                font-size: $font-size-12;
              }
            }
          }
        }
    
        .dashboard-view-select {
          @media #{$mobile-portait-only} {
            width: 100%;
            z-index: 1;
            position: relative;
            top: auto;
            left: auto;
            text-align: center;
    
            > svg {
              left: auto;
              right: 11px;
            }
          }
        }
      }
    }

    .dashboard-view-select {
      min-width: 150px;
    }
  }

  .dashboard-view-select:before,
  .dashboard-view-select:after,
  .state-select:before,
  .state-select:after {
    content: unset;
  }

  &.map-mode {
    .dashboard-view-select, .advanced-search {
      position: absolute;
      z-index: 1;
      left: 15px;
      transition: top 0.3s ease-in-out;
    }

    .advanced-search {
      top: 15px;

      @media #{$mobile-only} {
        top: 60px;
      }

      @media #{$mobile-portait-only} {
        position: static;

        > .MuiButtonGroup-root {
          display: flex;

          > .MuiButtonBase-root {
            display: flex;
            flex: 1;
            font-size: $font-size-12;
          }
        }
      }
    }

    .dashboard-view-select {
      top: 60px;
      min-width: 120px;

      @media #{$mobile-only} {
        top: 105px;
      }

      @media #{$mobile-portait-only} {
        position: relative;
        top: auto;
        left: auto;
        text-align: center;

        > svg {
          left: auto;
          right: 11px;
        }
      }
    }

    .map-controls {
      &__load-indicator {
        position: absolute;
        z-index: 1;
        right: 15px;
        top: 15px;

        @media #{$mobile-only} {
          right: auto;
          left: 15px;
        }

        @media #{$mobile-portait-only} {
          position: static;
        }
      }
    }
  }
}

.i2-p2-search-box-container {
  .i2-p2-search-box {
    position: absolute;
    z-index: 9;
    top: 116px;
    right: 10px;
    background-color: #fff;
    font-family: Roboto;
    margin-left: 10px;
    font-size: $font-size-15;
    font-weight: 300;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 300px;
  }

  .controls {
    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }

  .i2-p2-search-box:focus {
    border-color: #4d90fe;
  }
}

.heatmap-button {
  position: absolute;
  top: 80px;
  left: 5px;
}

.dashboard-view-select,
.state-select {
  background-color: white;
  border-radius: 2px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, math.div(1, 8.5)) 0 1px 6px, rgba(0, 0, 0, math.div(1, 8.5)) 0 1px 4px;

  hr {
    display: none;
  }
}

.state-select {
  right: 5px;
  top: 10px;
}

.i2p2-autocomplete {
  position: absolute;
  top: 130px;
  right: 10px;
  width: 200px;
  background-color: white;
  border-radius: 5px;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px
}

.i2p2-autocomplete-mobile {
  position: absolute;
  top: 80px;
  right: 5px;
  width: 185px;
  background-color: white;
  border-radius: 5px;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px
}

.i2p2-heatmap {
  position: absolute;
  bottom: 15px;
  left: 15px;
  height: 40px;
  width: 200px;
  background: white;
  z-index: 1;
  border-radius: 5px;
  display: table;

  .gradient {
    display: table-cell;
    vertical-align: middle;
    font-family: Roboto;
    font-size: $font-size-10;
    color: gray;
    text-align: center;

    .density {
      font-weight: bold;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .gradient-line {
      height: 12px;
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 3px;
    }

    .left {
      float: left;
      padding-left: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .right {
      float: right;
      padding-right: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}

.chargers-list-container {
  @include listWrapper;
  z-index: 1;

  .chargers-table {
    @include listGrid;
  }
}


