.rapid-search-container {
  position: absolute;
  top: 10px;
  left: 150px;
  z-index: 2;

  @media (max-width: 768px) {
    left: 5px;

    .query-builder-wrapper {
      margin-bottom: 10px;
    }
  }

  .presets-header {
    padding-left: 0 !important;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
  }

  .active-chip {
    background-color: rgb(234, 234, 234) !important;
  }

  .presets {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 710px;
    padding: 4px;
    margin: 0px -16px 0px -16px;
    overflow-y: auto;
    max-height: 120px;
    background-color: rgba(184, 184, 184, 0.12);
    box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;

    .preset-wrapper {
      display: flex;
      align-items: center;
    }

    .filter-message {
      color: #B2B2B2;
      font-size: 13px;
      margin: 5px;
    }
  }

  .rapid-search-popup {
    margin-top: 5px;
    min-width: 591px;
    display: flex;
    flex-direction: column;

    .queryBuilder {
      position: relative;

      .ruleGroup {
        @media #{$tablet-and-phone}{
          max-height: calc(100dvh - #{$footer-mobile-height} - 394px);
        }
        margin: 30px -15px 0px 0px;
        max-height: calc(100dvh - #{$footer-height} - 394px);
        overflow-y: auto;
        display: inline-block;
        padding: 2px;

        .add-filter-btn {
          top: -7px;
          position: absolute !important;
        }

        .rule {
          padding-bottom: 2px;
        }
      }
    }

    @media (max-width: 768px) {
      max-width: calc(100vw - 10px);
      min-width: inherit;
    }

    .field-select {
      @media (max-width: 768px) and (orientation: portrait) {
        width: 15dvh !important;
      }
    }

    .operator-select {
      @media (max-width: 768px) and (orientation: portrait) {
        width: 15dvh !important;
      }
    }

    .value-editor {
      @media (max-width: 768px) and (orientation: portrait) {
        width: 15dvh !important;
      }

      &.autocomplete > div {
        height: 48px;
      }
    }

    .rule {
      display: flex;
    }

    .apply-btn {
      float: left;
      margin-left: 10px;
    }

    .save-btn {
      float: right;
    }
  }

  .search-controls-wrapper {
    margin-top: 15px;
  }
}

.modal-inner {
  display: flex;
  flex-direction: column;
}

.rapid-search-popover {
  max-height: 50dvh !important;
  overflow-y: auto;
}

.queryBuilder {
  .ruleGroup {
    .rule {
      .MuiInput-root {
        @media (max-width: 768px) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }

      .MuiInput-root:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .query-builder-wrapper {
    margin-bottom: 20px;
  }

  .queryBuilder {
    .rule {
      margin-top: 15px;
    }
  }
}

.search-preset-form {
  margin-top: -20px;
  margin-bottom: 20px;

  .contents {
    display: block;
    margin-top: 15px;
  }
}
