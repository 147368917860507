@import "../../common/styles/_mixins";

.accounts-wrapper {
}

.account-buttons {
  margin: 5px;
}

.admin-tools {
  display: flex;
  width: 100vw;

  .admin-menu {
    flex-basis: 20%;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: #F6F6F6;
    overflow: auto;

    .menu-item {
      font-size: 16px !important;
      font-family: Roboto, sans-serif !important;
      color: #000000 !important;

      border: 10px;
      box-sizing: border-box;
      display: block;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      cursor: pointer;
      text-decoration: none;
      margin: 0;
      padding: 16px;
      outline: none;
      font-weight: inherit;
      position: relative;
      z-index: 1;
      color: rgba(0, 0, 0, 0.87);
      line-height: 16px;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      background: none;

      &.active {
        color: #3D9275 !important;
        background-color: #ECECEC !important;
        border-left: 3px solid #3D9275 !important;
      }

      &:hover {
        color: #3D9275 !important;
      }
    }
  }

  .admin-wrapper {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;

    .content-wrapper {
      @include listWrapper;
    }
  }

  .mobile-admin-wrapper {
    position: absolute;
    top: 48px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .ReactTable {
    height: calc(100dvh - 180px) !important;
  }
}

.admin-tools.mobile {
  flex-direction: column;

  .ReactTable {
    @include listWrapper;
    border-left: 0;
    border-right: 0;
    right: 0;
    top: 53px;
    height: auto !important;
  }
}

.fwToolbarControlsWrapper {
  display: flex;
  align-items: center;

  @media #{$mobile-only} {
    flex-direction: column;
    padding-top: 7px;
  }

  .update-btn, .refresh-btn {
    margin-left: 10px;

    .MuiSvgIcon-root {
      margin-right: 0 !important;
    }
  }

  & > * {
    margin-left: 10px;
  }

  .fwVersionSelect {
    min-width: 200px;
  }

  @media #{$tablet-and-phone} {
    .fwVersionSelect {
      min-width: 200px;
      width: calc(100vw - 110px) !important;
      max-width: 250px !important;
    }
    .refresh-btn {
      margin-left: unset;
      margin-right: 4px;
      min-width: 40px !important;
    }
    .update-btn {
      min-width: 60px !important;
    }
  }

}

.dialog__lists {
  p {
    text-align: left;
  }

  p.indented {
    margin-left: 8px;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-100 {
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }
}
