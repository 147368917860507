.change-password-form,
.change-email-form {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  width: 256px;

  .MuiTextField-root {
    margin-bottom: 20px;
  }

  .error-message {
    position: relative;
    bottom: 2px;
    font-size: 12px;
    line-height: 12px;
    color: $error-color;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    margin-top: 20px;
  }

  .success-message {
    position: relative;
    bottom: 2px;
    font-size: 12px;
    line-height: 12px;
    color: green;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    margin-top: 20px;
  }

  .pass-submit-btn {
    margin-top: 20px;
  }
}
