.withTooltip > * {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -6px;
    left: 0;
    border-bottom: 1px dashed transparent;
    border-bottom-color: rgba(47, 161, 122, 0.75);

    @media #{$mobile-only}{
      border-bottom: none;
    }
  }

  &:hover {
    &:after {
      border-bottom: 1px solid;
      border-bottom-color: rgba(47, 161, 122, 0.75);
    }
  }
}

.tippy-tooltip {
  background-color: $tooltipBackgroundColor !important;
}
