.confirmation-dialog-text {
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  padding: 16px 0;

  @media #{$mobile-only} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0 -15px;
    grid-gap: 15px;

    table {
      th {
        display: none;
      }

      tr:nth-child(2) {
        background: #f5f5f5;
      }

      td {
        display: grid;
        grid-template-columns: 15ch auto;
        text-align: left;
        word-break: break-word;

        &::before {
          content: attr(data-cell) ": ";
          font-weight: 700;
          text-transform: capitalize;
        }
      }
    }
  }
}

.confirmation-dialog-warning {
  text-align: center;
  color: $error-color;
  margin-top: 40px;
}
