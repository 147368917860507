@mixin listWrapper {
  background: white;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

@mixin listGrid {
  background: white;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  height: calc(100dvh - 89px);

  @media #{$tablet-and-phone}{
    height: calc(100dvh - 127px);
  }
}