.role-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.role-form-footer {
  display: flex;
  justify-content: space-between;
}

.role-form-button {
  width: 125px;
}
