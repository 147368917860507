$energy-connector-color-in: #72c499;
$energy-connector-color-out: #fdc461;
$energy-connector-arrow: transparent;
$energy-flow-direction-color-main-in: $energy-connector-color-in;
$energy-flow-direction-color-main-out: $energy-connector-color-out;
$energy-flow-direction-color-animation: transparent;
$energy-flow-direction-arrow-size: 3px;
$energy-flow-direction-arrow-margin: 10px;
$energy-connector-animation-duration: 45s;

.editor-container {

  .map-container {
    .energy-connector-direction, .energy-connector {
      display: none !important;

      &.NONE {
        display: block !important;
      }
    }
  }

  .energy-connector-direction {
    position: absolute;
    z-index: 9;
    transform: translate(-21px);

    &.horizontal.left {
      transform: scaleX(-1) translate(-21px);
    }

    &.reverse {
      animation: mirror 9600ms linear infinite;

      .energy-connector-direction-arrow {
        &.IN, &.OUT {
          animation: energy-flow-direction-reverse 1200ms linear infinite;
        }

        &.two {
          animation-delay: 100ms;
        }

        &.three {
          animation-delay: 200ms;
        }
      }
    }

    .energy-connector-direction-arrow {
      transform: rotate(-45deg);
      position: absolute;

      &.IN {
        animation: energy-flow-direction-in 1200ms linear infinite;
        border-bottom: $energy-flow-direction-arrow-size solid $energy-flow-direction-color-main-in;
        border-right: $energy-flow-direction-arrow-size solid $energy-flow-direction-color-main-in;
      }

      &.OUT {
        animation: energy-flow-direction-out 1200ms linear infinite;
        border-bottom: $energy-flow-direction-arrow-size solid $energy-flow-direction-color-main-out;
        border-right: $energy-flow-direction-arrow-size solid $energy-flow-direction-color-main-out;
      }

      &.one {
        margin: 0;
      }

      &.two {
        margin: 0 $energy-flow-direction-arrow-margin;
        animation-delay: 100ms;
      }

      &.three {
        margin: 0 ($energy-flow-direction-arrow-margin * 2);
        animation-delay: 200ms;
      }

      @keyframes energy-flow-direction-in {
        0% {
          opacity: 1;
        }

        90% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      @keyframes energy-flow-direction-out {
        from {
          border-bottom: solid $energy-flow-direction-color-main-out;
          border-right: solid $energy-flow-direction-color-main-out;
        }

        to {
          border-bottom: solid $energy-flow-direction-color-animation;
          border-right: solid $energy-flow-direction-color-animation;
        }
      }

      @keyframes energy-flow-direction-reverse {
        0% {
          border-bottom: solid $energy-flow-direction-color-main-in;
          border-right: solid $energy-flow-direction-color-main-in;
        }

        50% {
          border-bottom: solid $energy-flow-direction-color-main-out;
          border-right: solid $energy-flow-direction-color-main-out;
        }

        100% {
          border-bottom: solid $energy-flow-direction-color-main-in;
          border-right: solid $energy-flow-direction-color-main-in;
        }
      }
    }

    @keyframes mirror {
      0% {
        transform: scaleX(-1) translate(-21px);
      }

      48% {
        transform: scaleX(-1) translate(-21px);
      }

      50% {
        transform: scaleX(1) translate(-21px);
      }

      98% {
        transform: scaleX(1) translate(-21px);
      }

      100% {
        transform: scaleX(-1) translate(-21px);
      }
    }
  }

  .energy-connector {
    background-size: 4px 4px;
    z-index: 2;
    position: absolute;

    &.reverse::before {
      content: '';
      position: absolute;
      background-size: 4px 4px;
      z-index: 5;
    }

    &.vertical.reverse::before {
      width: 4px;
      height: 100%;
      left: -4px;
    }

    &.horizontal.reverse::before {
      height: 4px;
      width: 100%;
      top: -4px;
      left: -4px;
    }

    &.in {
      z-index: 3;
    }

    &.vertical.down.IN {
      background-image: linear-gradient(0deg, $energy-connector-color-in, $energy-connector-color-in 70%, white 70%, white 100%);
      animation: gradient-y-down $energy-connector-animation-duration linear infinite;
    }

    &.vertical.down.IN.reverse::before {
      background-image: linear-gradient(0deg, $energy-connector-color-out, $energy-connector-color-out 70%, white 70%, white 100%);
      animation: gradient-y-up $energy-connector-animation-duration linear infinite;
    }

    &.vertical.up.IN {
      background-image: linear-gradient(0deg, $energy-connector-color-in, $energy-connector-color-in 70%, white 70%, white 100%);
      animation: gradient-y-up $energy-connector-animation-duration linear infinite;
    }

    &.horizontal.right.IN {
      background-image: linear-gradient(90deg, $energy-connector-color-in, $energy-connector-color-in 70%, white 70%, white 100%);
      animation: gradient-x-right $energy-connector-animation-duration linear infinite;
      z-index: 6;
    }

    &.horizontal.right.IN.reverse::before {
      background-image: linear-gradient(90deg, $energy-connector-color-out, $energy-connector-color-out 70%, white 70%, white 100%);
      animation: gradient-x-left $energy-connector-animation-duration linear infinite;
    }

    &.horizontal.left.IN {
      background-image: linear-gradient(90deg, $energy-connector-color-in, $energy-connector-color-in 70%, white 70%, white 100%);
      animation: gradient-x-left $energy-connector-animation-duration linear infinite;
      z-index: 6;
    }

    &.horizontal.left.IN.reverse::before {
      background-image: linear-gradient(90deg, $energy-connector-color-out, $energy-connector-color-out 70%, white 70%, white 100%);
      animation: gradient-x-right $energy-connector-animation-duration linear infinite;
    }

    &.vertical.down.OUT {
      background-image: linear-gradient(0deg, $energy-connector-color-out, $energy-connector-color-out 70%, white 70%, white 100%);
      animation: gradient-y-down $energy-connector-animation-duration linear infinite;
    }

    &.vertical.up.OUT {
      background-image: linear-gradient(0deg, $energy-connector-color-out, $energy-connector-color-out 70%, white 70%, white 100%);
      animation: gradient-y-up $energy-connector-animation-duration linear infinite;
    }

    &.horizontal.right.OUT {
      background-image: linear-gradient(90deg, $energy-connector-color-out, $energy-connector-color-out 70%, white 70%, white 100%);
      animation: gradient-x-right $energy-connector-animation-duration linear infinite;
    }

    &.horizontal.left.OUT {
      background-image: linear-gradient(90deg, $energy-connector-color-out, $energy-connector-color-out 70%, white 70%, white 100%);
      animation: gradient-x-left $energy-connector-animation-duration linear infinite;
    }

    $energy-connector-color-none: darkgray;

    &.vertical.down.NONE,
    &.vertical.up.NONE,
    &.horizontal.right.NONE,
    &.horizontal.left.NONE {
      background: darkgray;
      animation: none;
    }

    @keyframes gradient-y-down {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: 200px;
      }
    }

    @keyframes gradient-y-up {
      0% {
        background-position-y: 200px;
      }
      100% {
        background-position-y: 0;
      }
    }

    @keyframes gradient-x-right {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: 200px;
      }
    }

    @keyframes gradient-x-left {
      0% {
        background-position-x: 200px;
      }
      100% {
        background-position-x: 0;
      }
    }
  }
}
