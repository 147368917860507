.sortable-columns {
  list-style-type: none;

  li {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: lightgrey;
    border: 1px solid gray;
    border-radius: 5px;
    margin: 5px;

    .reorder-container {
      padding-left: 20px;
      padding-right: 20px;
      height: 50px;
      float: left;
      display: block;

      .reorder {
        height: 50px !important;
      }
    }
  }
}

.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #F3F3F3;
  border-radius: 3px;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #E0E0E0;
  list-style: none;
  padding: 0;
  height: auto;
  margin: 0;
}

.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #FFF;
  border-bottom: 1px solid #EFEFEF;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
  z-index: 99999;
  text-align: center;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active,
  &.draggable {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .drag-btn-left {
    position: absolute !important;
    right: 20px !important;
  }

  .reorder-container {
    display: flex;
    align-items: center;

    .reorder {
      margin-right: 5px;
    }
  }
}

.SortableItem.hidden {
  background: lightgrey !important;
  opacity: 0.6 !important;
}

@media (max-width: 768px) {
  .SortableList {
    height: 80dvh;

    .SortableItem {
      .MuiButton-root {
        min-width: 70px;
      }
    }
  }
}
