@import "../../styles/mediaQueriesConstants";

.charger-last-update {
  display: flex;

  &-label {
    margin-right: 10px;
  }
}

@media #{$mobile-only} {
  .charger-last-update-label-wrapper {
    flex-direction: column;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .charger-last-update {
    &-label {
      font-size: 0.75em;
      color: #cccccc;
    }

    .withTooltip {
      font-size: 0.8em;
      margin-bottom: 5px;
    }
  }
}
