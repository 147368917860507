.root-node {
  padding-top: 20px;
  padding-left: 15px;

  div {
    padding-bottom: 5px;
    overflow-wrap: break-word;
    font-size: 14px;
  }

  b {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
  }
}

.nodes-list-collapsible {
  position: relative;
  height: 100%;

  .root-nodes {
    font-family: Roboto, sans-serif;

    .root-nodes-item-border {
      overflow-wrap: break-word;
      font-size: 15px;
      margin-left: 15px;
    }

    .root-nodes-item {
      padding-bottom: 9px;
      padding-top: 9px;
      overflow-wrap: break-word;
      font-size: 15px;
      border-bottom: 1px solid #F0F0F0;
    }

    b {
      padding-top: 30px;
      padding-bottom: 30px;
      display: block;
      font-weight: 500;
    }

    .section-title-expand {
      padding-bottom: 9px;
      padding-top: 9px;
      overflow-wrap: break-word;
      font-size: 15px;
      margin-left: 15px;
    }

    .section-title-expand-border {
      border-bottom: 1px solid #F0F0F0;
    }
  }
}

.nodes-list {
  position: relative;
  height: 100%;

  .root-nodes {
    font-family: Roboto, sans-serif;

    div {
      padding-bottom: 9px;
      padding-top: 9px;
      overflow-wrap: break-word;
      font-size: 15px;
      padding-left: 15px;
      border-bottom: 1px solid #F0F0F0;
    }

    b {
      padding-top: 10px;
      padding-bottom: 10px;
      display: block;
      font-weight: 500;
    }
  }
}

.node-select-form {
  overflow-x: hidden;
  background-color: #fff;
  overflow-y: auto;
  z-index: 21;

  .editor-container > & {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  @media #{$bigger-than-mobile} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 288px;
  }

  @media #{$mobile-only} {
    position: relative;
    width: 288px;
    height: 100%;
  }

  .close-button {
    float: right;
    padding-top: 14px !important;
    z-index: 1;
  }

  .no-nodes-block {
    position: absolute;
    top: 55px;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .label {
      height: 44px;
      width: 202px;
      opacity: 0.3;
      color: #101213;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.11px;
      line-height: 22px;
      text-align: center;
    }
  }

  .details {
    .values {
      padding-top: 2px;
      padding-bottom: 2px;
      padding-right: 15px;
      display: flex;
      justify-content: space-between;
      font-family: Roboto, sans-serif;

      > div {
        font-size: 13px !important;
      }

      .bold {
        font-weight: 500;
      }
    }
  }

  .desc {
    text-align: right;
  }
}

.logs {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 137px);

  @media #{$tablet-and-phone} {
    height: 100%;
  }

  .logs-filter {
    padding: 10px;
    border-bottom: 1px solid #D8D8D8;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
    display: flex;
    gap: 10px;
  }

  .logs-list {
    width: 288px;
    min-width: 288px;
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    overflow-y: auto;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;

    .log-item {
      padding: 5px 10px;
      background-color: #ffffff;
      border-bottom: 1px solid #D8D8D8;
      position: relative;

      &.new {
        animation: slideDown 0.8s ease;
        background-color: #F5FAFA;
      }

      &.START_CHARGING_SESSION,
      &.START_DISCHARGING_SESSION,
      &.AUTHORIZATION_REQUEST,
      &.START_APPLYING_LIMIT,
      &.EV_CONNECTED,
      &.CHARGER_STANDBY,
      &.EV_DISCONNECTED,
      &.NUMBER_OF_PHASES_CHANGED {
        background-color: #FDC461
      }

      .expand-icon {
        position: absolute;
        right: 5px;
        top: 12px;
      }

      .time-title {
        font-size: 12px;
        line-height: 18px;
        opacity: 0.5;
        color: #000000;
      }

      .id-title {
        font-size: 14px;
        color: black;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .bold {
        font-weight: 500;
      }

      .status {
        font-size: 12px;
        padding-top: 5px;
        padding-right: 15px;
      }

      .circle {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: black;

        &.FINISH_APPLYING_LIMIT,
        &.END_CHARGING_SESSION,
        &.ONLINE,
        &.PLAZA_CREATED,
        &.LOCAL_OA_CONFIRMED,
        &.CURRENT_BASED_OA_CONFIRMED,
        &.POWER_BASED_OA_CONFIRMED,
        &.OVER_CONSUMPTION_TA_CONFIRMED,
        &.UNDER_CONSUMPTION_TA_CONFIRMED {
          background-color: #9BD36D
        }

        &.START_CHARGING_SESSION,
        &.START_DISCHARGING_SESSION,
        &.AUTHORIZATION_REQUEST,
        &.START_APPLYING_LIMIT,
        &.EV_CONNECTED,
        &.CHARGER_STANDBY,
        &.EV_DISCONNECTED,
        &.NUMBER_OF_PHASES_CHANGED,
        &.MEASUREMENT_POINT_UPDATE {
          background-color: #FDC461
        }

        &.UNDER_CONSUMPTION_TRIGGER,
        &.OVER_PROTECTION_TRIGGER,
        &.FINISH_APPLYING_LIMIT_W_OWNER,
        &.ROGUE_CHARGERS_DETECTED,
        &.FINISH_PLAZA_NODE_POWER_LIMITS_UPDATE,
        &.FINISH_PLAZA_NODE_LOAD_LIMITS_UPDATE,
        &.FINISH_PLAZA_NODE_HARD_CONSTRAINT_LIMITS_UPDATE,
        &.FINISH_PLAZA_NODE_CHARGER_LIMITS_UPDATE,
        &.FINISH_PLAZA_NODE_DYNAMIC_LIMITS_UPDATE {
          background-color: #2E9393;
        }

        &.OFFLINE, &.FAULT {
          background-color: #D96266
        }
      }

      .values {
        font-size: 12px;
        padding-top: 2px;
        padding-bottom: 2px;
        display: flex;
        justify-content: space-between;

        &.with-disk {
          display: flex;
          padding-left: 15px;

          &:before {
            content: '•';
            display: block;
            position: absolute;
            left: 10px;
            font-weight: bold
          }
        }
      }
    }
  }
}

.event_type_option {
  display: flex;
  flex-direction: column;

  .event_type_code {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.54);
    line-height: unset;
  }

  .event_type_label {
    margin-top: -10px;
  }
}

.event_selected_items {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.8em;
  text-overflow: ellipsis;
  padding: 5px 0;
  font-style: italic;
}

.event_type_duration {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  > div {
    width: 100%;
  }
}

.section-title-expand {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .expand-icon {
    z-index: 1;
  }
}

.section-title-expand .title, .node-select-form .top-node-name {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: initial;
}

.section-title-expand .title {
  text-transform: uppercase !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-150%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.details-top-status {
  font-family: Roboto, serif;
  font-size: 15px;
  font-weight: 500;
  display: block;
  padding-bottom: 18px;
  padding-top: 18px;
  overflow-wrap: break-word;
  padding-left: 15px;
  text-transform: uppercase;
}

.details-top-status, .log-item {
  .circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: black;
    margin-left: 2px;

    &.green {
      background-color: #9BD36D;
    }

    &.gray {
      background-color: gray;
    }
  }
}

.expand-icon {
  transition: transform 1s;
  transform: rotate(-90deg);

  &.opened {
    transform: rotate(0deg);
  }
}

.details {
  transition: all .3s ease;

  &.closed {
    max-height: 0;
    opacity: 0
  }

  &.opened {
    opacity: 1;
    max-height: 1000px;
  }
}


