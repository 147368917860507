@import './colors';

$danger: $error-color; // red
$ok: $primary-color; // green
$disabled: #808080; // gray
$yellow: $yellow-color; // yellow
$normal: $black-color; // black
$pending: #009AD1; // blue

.label-available,
.label-active,
.label-alert,
.label-partially_oos,
.label-totally_oos,
.label-inactive,
.label-pending,
.label-monitored,
.label-unknown,
.c_condition-label-active,
.c_condition-label-inactive,
.c_condition-label-pending,
.c_online-label-yes,
.c_online-label-no,
.c_charging-label-connected_not_charging,
.c_charging-label-not_connected,
.c_charging-label-reserved_cable_disconnected,
.c_charging-label-connected_charging,
.c_charging-label-faulted,
.c_charging-label-unknown,
.c_fw-update-label-applied,
.c_fw-update-label-cancelled,
.c_fw-update-label-error-failed,
.c_fw-update-label-in_progress-sent,
.c_fw-update-label-manufactured,
.c_fw-update-label-response_time_out,
.c_fw-update-label-postponed,
.c_fw-update-label-time_out,
.c_fw-update-label-queued,
.c_fw-update-label-unresponsive,
.c_task_status-label-pending,
.c_task_status-label-canceled,
.c_task_status-label-in_progress,
.c_task_status-label-completed,
.c_task_status-label-uploading,
.c_task_status-label-unresponsive,
.c_task_status-label-done,
.c_task_status-label-error,
.c_configuration-label-changes_pending,
.c_configuration-label-reboot_required,
.c_configuration-label-live {
  width: 12px;
  min-width: 12px;
  display: inline-flex;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  flex: none;
}

.label-inactive,
.label-unknown,
.c_charging-label-unknown,
.c_condition-label-inactive,
.c_task_status-label-unresponsive {
  background-color: $disabled;
}

.label-available,
.label-active,
.c_condition-label-active,
.c_online-label-yes,
.c_charging-label-connected_charging,
.c_fw-update-label-applied,
.c_fw-update-label-in_progress-sent,
.c_fw-update-label-manufactured,
.c_task_status-label-in_progress,
.c_task_status-label-completed,
.c_task_status-label-done,
.c_configuration-label-live {
  background-color: $ok;
}

.label-alert,
.label-imported,
.c_charging-label-not_connected,
.c_charging-label-reserved_cable_disconnected,
.c_configuration-label-reboot_required {
  background-color: $yellow;
}

.label-partially_oos,
.c_task_status-label-canceled,
.c_task_status-label-error,
.c_fw-update-label-cancelled,
.c_fw-update-label-error-failed,
.c_fw-update-label-response_time_out,
.c_fw-update-label-time_out,
.c_fw-update-label-unresponsive,
.c_charging-label-faulted {
  background-color: $danger;
}

.label-totally_oos,
.c_online-label-no {
  background-color: $normal;
}

.label-monitored,
.label-pending,
.c_task_status-label-pending,
.c_condition-label-pending,
.c_charging-label-connected_not_charging,
.c_fw-update-label-postponed,
.c_fw-update-label-queued,
.c_task_status-label-uploading,
.c_configuration-label-changes_pending {
  background-color: $pending;
}

.circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;

  &.red {
    background-color: $danger;
  }

  &.yellow {
    background-color: $yellow;
  }

  &.green {
    background-color: $ok;
  }

  &.blue {
    background-color: $pending;
  }

  &.black {
    background-color: $normal;
  }

  &.gray {
    background-color: $disabled;
  }
}
