@import "../../common/styles/colors";
@import "../../common/styles/fonts";

.rt-thead {
  background-color:  #f7f8f8;

  .rt-resizable-header-content {
    padding: 0 0 0;
    font-size: $font-size-12;
  }

  .date-picker-filter-container {
    .calendarIcon svg {
      height: 20px;
    }
  }

  .table-material-text {
    font-size: $font-size-12 !important;
    height: 26px !important;
  }

  .Component-select-9 .MuiSelect-selectMenu {
    padding: 6px 24px 6px 7px;
  }
}

.checkbox-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: rgba(55, 70, 73, 0.04);

  .MuiCheckbox-root {
    padding: 0;
  }

  &.table-multi-select-container {
    background: transparent;
  }

  .checkbox-wrapper {
    margin-right: 0;
  }
}

.sticky-cell,
.sticky-header {
  position: sticky !important;
  left: 0;
  z-index: 1;
}

.sticky-cell:has(+ .sticky-cell),
.sticky-header:has(+ .sticky-header) {
  border-right-width: 1px !important;
}

.sticky-cell + .sticky-cell,
.sticky-header + .sticky-header {
  left: 65px;
}

.table-link-header,
.checkbox-cell-header,
.checkbox-cell {
  background-color:  #f7f8f8 !important;
}

.rt-th {
  font-weight: 700;
  font-size: $font-size-12;

  &.-sort-asc {
    color: #559178;
    box-shadow: inset 0 3px 0 0 #559178 !important;
  }

  &.-sort-desc {
    color: #559178;
    box-shadow: inset 0 -3px 0 0 #559178 !important;
  }
}

.rt-td {
  font-size: $font-size-14;
  line-height: 16px;
  display: flex;
  align-items: center;
  height: 48px;
}

.rt-td.nested-table {
  padding: 0;

  .nested-rows-container {
    width: 100%;

    .nested-row {
      padding: 5px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .nested-row:last-child {
      border-bottom: 0;
    }
  }
}

.ReactTable {
  height: 100%;
  border: none;
  outline: solid 1px rgba(0, 0, 0, 0.1);
  color: $dark-color;

  .rt-td {
    text-align: start;
  }

  .rt-tbody {
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, transparent 48px, $table-border-color 48px, $table-border-color 49px);
    background-size: 100% 49px;

    .rt-tr-group {
      flex: none;
      border: none;

      & > .-padRow {
        display: none;
      }

      .rt-tr {
        border-bottom: solid 1px $table-border-color;

        &.-even, &.-odd {
          .rt-td, .MuiSwitch-root {
            transition: .3s;
          }
        }

        &.-even {
          .rt-td {
            background-color: $table-row-bgColor;
          }
        }

        &.-odd {
          .rt-td {
            background-color: #ffffff;
          }
        }
      }
    }
  }

  &.non-filterable {
    .rt-thead {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    &.-even, &.-odd {
      .rt-td {
        background-color: $table-hover-row;
      }

      .MuiSwitch-root {
        box-shadow: 0 0 0 1px #4a9179;
        border-radius: 15px;
      }
    }
  }

  &.sticky {
    @media #{$tablet-and-phone} {
      outline-offset: calc(#{$pagination-height} + 1px);
      margin-top: #{$pagination-height};
      height: calc(100dvh - #{$pagination-height} - #{$footer-mobile-height} - #{$navbar-mobile-height});
      font-size: $font-size-14;

      .rt-table {
        bottom: #{$pagination-height}
      }

      .pagination-bottom {
        position: fixed;
        bottom: #{$footer-mobile-height};
        left: 0;
        right: 0;
        background: #ffffff;
        z-index: 1;
      }
    }
  }

  &.sticky-modal {
    @media #{$tablet-and-phone} {
      height: 100% !important;
    }
  }

  &:not(.sticky) {
    margin-top: unset;
    outline-offset: unset;
    height: unset;

    .rt-table {
      bottom: unset;
    }

    @media #{$tablet-and-phone} {
      .pagination-bottom {
        position: relative;
        bottom: unset;
        left: unset;
        right: unset;
        background: unset;
      }
    }
  }

  @media #{$tablet-and-phone} {
    .rt-noData {
      top: 25%;
    }
  }

  .rt-td:first-letter {
    text-transform: capitalize;
  }

  .rt-table {
    position: relative;

    .rt-thead {
      &.-header {
        box-shadow: none;
        position: sticky;
        top: 0;
        height: 37px;
        z-index: 1;

        .rt-th {
          padding: 12px 8px 5px;

          &.checkbox-cell-header {
            padding-left: 23px;
          }
        }

        .rt-tr {
          height: 36px;
        }
      }

      &.-filters {
        position: sticky;
        top: 36px;
        z-index: 1;

        input,
        select {
          border-radius: 4px;
          border: 1px solid $table-border-color;
        }

        .rt-th {
          border-right: 1px solid $table-border-color;
          padding: 0 8px 11px 8px;
        }
      }

      .rt-tr {
        text-align: left;
      }
    }

    .rt-tbody {
      overflow: visible;
    }

    .break-line-cell {
      word-wrap: break-word;
      white-space: initial;
    }
  }


  .rt-thead.-filters input,
  .rt-thead.-filters select {
    font-size: $font-size-12;
    padding: 2px 7px;
  }
}
