.date-picker-container {
  position: relative !important;
  display: flex !important;
  margin-left: unset !important;

  &.compact {
    input {
      font-size: 12px !important;
      height: 58px !important;
    }
    &>div>div {
      height: 64px !important;
    }
    &>div>div>div:first-of-type {
      font-size: 12px !important;
      line-height: 18px !important;
    }
    .calendarIcon {
      right: -12px !important;
      top: 15px !important;
    }
  }

  .calendarIcon {
    opacity: 0.65 !important;
    position: absolute !important;
    right: 0 !important;
    top: 22px !important;
  }

  .fileUploadIcon {
    opacity: 0.65 !important;
    position: absolute !important;
    right: 0 !important;
  }

  .image-preview {
    padding: 0 !important;
    margin-right: 10px !important;

    &.customer {
      top: -10px;
    }
  }

  .clearable-input-with-image-preview {
    width: calc(100% - 75px) !important;
  }

  .clearable-input {
    width: calc(100% - 25px) !important;
  }

  .clear-button {
    position: absolute !important;
    right: 30px !important;
  }
}

.date-picker-container.file-loader {
  padding-right: 50px !important;
  display: flex;
  align-items: center;
}

.autocompleteWrapper {
  & > div:focus {
    outline: none;
  }
}

.floatingLabel {
  position: absolute;
  line-height: 22px;
  top: 20px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  transform: scale(0.75) translate(0px, -28px);
  transform-origin: left top 0px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.298039);
  -webkit-user-select: none;
  font-size: 16px;
}

.error-message {
  position: relative;
  bottom: 2px;
  font-size: 12px;
  line-height: 12px;
  color: $error-color;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.autocompleteWrapper.disabled {
  div > hr:first-child {
    border-top: none rgba(0, 0, 0, 0.3) !important;
    border-left: none rgba(0, 0, 0, 0.3) !important;
    border-right: none rgba(0, 0, 0, 0.3) !important;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.3) !important;
  }

  div > hr:nth-child(2) {
    display: none;
  }

  svg {
    fill: rgba(0, 0, 0, 0.3) !important;
  }
}

.autocompleteWrapper.read-only {
  svg {
    opacity: 0 !important;
    user-select: none !important;
  }
}

.autocompleteWrapper {
  display: flex;

  &.selected > div {
    width: 400px !important;
  }

  div {
    font-family: Roboto, sans-serif;
  }

  svg {
    //fill: black !important;
    transform: none !important;
  }

  .resetOwnerButton {
    bottom: -25px;
    position: relative;
    padding: 0 !important;
    flex-shrink: 0;
    margin-right: -20px !important;
    margin-left: 7px !important;
    width: 42px !important;
    height: 42px !important;
  }
}

.cursor-auto {
  cursor: auto !important;
}

.date-picker-filter-container {
  position: relative !important;

  .calendarIcon {
    height: 27px !important;
    width: 27px !important;
    padding: 0 !important;
    position: absolute !important;
    right: 0;
    top: 0px;

    .dropdown__textOrDate & {
      right: 25px
    }

    svg {
      color: rgba(55, 70, 73, 0.1) !important;
    }
  }
}

.hidden-datePicker {
  display: none;
}

.filter-gray-icon {
  color: rgb(117, 117, 117) !important;
}

.charger-details-form-select {
  height: 26px;
}
