.footer {

  @media  #{$tablet-and-phone}{
    height: $footer-mobile-height;
    padding: 0 10px;
  }

  width: 100%;
  height: $footer-height;
  background-color: $primary-green-color;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  padding: 0 6px;
  box-sizing: border-box;
  z-index: 1;

  @media (max-width: 375px) and (orientation: portrait) {
    font-size: 10px;
  }

  div {
    @media  #{$tablet-and-phone}{
      white-space: nowrap;
    }

    display: flex;
    align-items: center;

    img {
      height: 12px;
    }
  }
}
