@import "./../../../common/styles/mediaQueriesConstants";

.advanced-search-modal {
  max-height: 70dvh;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: unset !important;
}

.advanced-search-warning-message {
  padding: 30px 0 18px 0;
}

.advanced-search-dialog {
  .MuiDialogActions-root {
    justify-content: flex-start;
  }

  .MuiChip-root {
    margin: 5px;
  }

  .advanced-search-dialog-footer {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }

  .advanced-search-actions-wrapper {
    display: flex;
    margin: 0;
    padding: 8px;

    button + button {
      margin-left: 15px;
    }
  }

  .advanced-search-apply-filter-btn {
    margin-left: auto;
  }

  .advanced-search-saved-filters {
    margin: 0;
    padding: 3px 0;
    border-bottom: 1px solid #ccc;
  }

  .query-builder .ruleGroup::after {
    border-left-style: dashed;
  }

  .charger-package__field {
    height: 33px;
  }

  &.mobile {
    .MuiDialogContent-root {
      padding: unset;
    }

    .MuiDialog-container {
      .MuiButton-root {
        min-width: 85px;
      }
    }

    .advanced-search-modal {
      max-height: unset !important;
      overflow-y: auto !important;
      justify-content: space-between;
      height: unset;
    }

    .rule-group-base {
      max-height: 5000px;
      transition: opacity 0.05s ease-in-out, max-height 0.05s ease-in-out;

      .query-builder-add-rule-btn, .query-builder-add-group-btn {
        opacity: 1;
      }

      &.query-collapsed {
        max-height: 33px;
        overflow: hidden;
        margin-bottom: 2px;

        .query-builder-add-rule-btn, .query-builder-add-group-btn {
          pointer-events: none;
          opacity: 0;
        }
      }
    }

    .ruleGroup {
      padding-top: unset;
      margin-left: unset !important;

      .rule {
        flex-direction: column;
        margin-top: unset;
        margin-left: unset !important;
        align-items: center;
        padding-bottom: 16px;
        padding-top: 16px;

        .MuiInput-root {
          margin: unset;
        }

        .MuiTextField-root {
          margin-right: 21px !important;
        }

        .query-builder-value-charger-component {
          width: 85% !important;
        }

        .query-builder-value-charger-selector {
          width: 96% !important;
        }

        .query-builder-field-selector, .query-builder-operator-selector {
          width: 85%;
          margin-right: 10px !important;

          .MuiInputBase-root {
            width: fit-content !important;
          }
        }

        & > .MuiFormControl-root {
          width: calc(85% - 10px) !important;
          margin-right: 18px;
        }

        & > .MuiInput-root {
          width: 85% !important;
          margin-right: unset !important;
        }

      }

      &:after {
        margin-left: unset;

        height: 45px;
      }

      .query-builder-add-rule-btn {
        left: 10px;
        bottom: 2px;
      }

      .query-builder-add-group-btn {
        left: 175px;
        bottom: 2px;
      }
    }

    .advanced-search-query-bilder-container {
      padding: unset;
      overflow-y: unset;
      max-height: unset;
      min-height: unset;
      margin: unset;
    }

    .search-query-expression {
      margin: unset;
    }

    .query-builder-combinator-selector {
      width: 100%;

      .combinator-selector-container {
        width: 100%;
      }
    }

    .query-builder-offset-line {
      display: none;
    }

    .query-builder-combinator-indicator {
      display: none;
    }

    .query-builder-remove-group-btn {
      margin-left: -50px;
      margin-top: 0;
    }

    .query-builder-remove-rule-btn {
      position: absolute;
      right: 5px;
    }

    .query-builder-collapse-button {
      right: 10px;
      position: absolute;
      top: 0;
      padding: 7px 0;
      color: #fff;
      transition: .2s;

      &.collapsed {
        transform: rotate(90deg);
      }
    }
  }
}

.date-range-picker-advanced-search {
  > div {
    height: 100%;
    width: 120px;

    button {
      padding: unset;
    }
  }
}

.query-builder-operator-selector {
  margin-right: unset !important;
}

.search-query-expression {
  margin: 0 -24px -19px;
}

.rule {
  @media #{$tablet-and-phone} {
    position: relative;

    .charger-component-selector {
      margin-bottom: 40px;
    }

    .query-builder-value-charger-selector, .query-builder-value-charger-component {
      height: 120px !important;
    }

    .query-builder-value-charger-selector {
      margin-top: 8px;
    }

    .charger-package__field {
      height: 30px;
    }

    .query-builder-value-charger-component {
      position: absolute;
      top: 100px;
      pointer-events: none;

      & > div {
        pointer-events: all;
      }
    }
  }
}
