.cluster-marker {
  position: absolute;
  cursor: pointer;
  border: 3px solid white;
  border-radius: 50%;
  background-color: transparent;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.simple-marker-container {
  top: -22px;

  .simple-marker {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    top: -26px;
    left: -20px;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-size: 40px 40px;
  }
}

.simple-marker.available {
  background-image: url("/images/markers/green-pin.svg");

  &.charging {
    background-image: url("/images/markers/green-active-pin.svg");
  }
}

.simple-marker.partially_oos {
  background-image: url("/images/markers/red-pin.svg");

  &.charging {
    background-image: url("/images/markers/red-active-pin.svg");
  }
}

.simple-marker.alert {
  background-image: url("/images/markers/yellow-pin.svg");

  &.charging {
    background-image: url("/images/markers/yellow-active-pin.svg");
  }
}

.simple-marker.monitored {
  background-image: url("/images/markers/turquoise-pin.svg");

  &.charging {
    background-image: url("/images/markers/turquoise-active-pin.svg");
  }
}

.simple-marker.totally_oos {
  background-image: url("/images/markers/dark-grey-pin.svg");

  &.charging {
    background-image: url("/images/markers/dark-grey-active-pin.svg");
  }
}

.simple-marker.unknown {
  background-image: url("/images/markers/grey-pin.svg");

  &.charging {
    background-image: url("/images/markers/grey-active-pin.svg");
  }
}

.i2p2-marker-content {
  width: 280px;
  font-family: Roboto;
  color: gray;
  font-size: 12px;
  line-height: 12px;

  &.cluster-details {
    max-height: 300px;
    overflow-y: auto
  }

  .repeated-info:last-child .full-width-border {
    display: none;
  }

  .container {
    display: flex;
    margin-top: 5px;

    .flash-icon {
      width: 26px;
      height: 19px
    }

    .name-caption {
      line-height: 24px;
      color: #444444;
      text-decoration: underline
    }
  }

  .info-container {
    padding-left: 10px;

    p {
      span {
        word-break: break-all !important;
        color: #000 !important;
      }

      span.capitalized {
        text-transform: capitalize !important;
      }
    }
  }
}

.i2p2-cluster {
  width: 175px;

  .container {
    padding: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px white;
    border-bottom-style: groove;
  }

  .container:last-child {
    border-bottom: none;
  }
}

.cluster-text {
  z-index: 999999;
}

.cluster-pie-chart {
  position: absolute;
  top: 0;
}