#createEditChargerForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f0f0f0;

  & > form {
    height: 100%;
  }

  @media #{$mobile-only} {
    height: 100%;
    overflow-y: auto;
  }

  .upperCaseFirstLetter {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .charger-details-tab {
    .MuiGrid-spacing-xs-2 {
      width: unset;
      margin: unset;
    }

    .MuiGrid-root {
      padding: 8px;
    }
  }

  label {
    font-family: Roboto, sans-serif;
    display: flex;
    align-items: center;
    color: rgba($dark-color, 0.5) !important; // rewrote MUI
    user-select: auto !important; // rewrote MUI

    .label--withInfo {
      display: flex;
      align-items: center;
    }

    &.label--auto-activated {
      font-size: 0.75rem;
      color: rgba($dark-color, 0.8) !important; // rewrote MUI
      margin-top: 3px;
      font-weight: 500;

      .icon {
        color: $yellow-color;
        font-size: 1.1rem;
        margin-right: 5px;
      }
    }
  }

  .tab-select {
    margin: 20px;
  }

  .charger-state-tab {
    label {
      width: 100%;
    }

    .connector-state {
      &__label {
        margin-top: 13px;
      }
      &__select {
        margin-top: 31px;
      }
    }
  }

  .charger-details-tab,
  .charger-state-tab {
    overflow: auto;

    .no-data-label {
      text-align: center;
      width: 100%;
      padding: 20px;
    }

    .charger-details-section {
      box-sizing: border-box;

      &.s-2 {
        flex-basis: calc(100% / 2);
        min-width: 300px;
      }

      &.s-3 {
        flex-basis: calc(100% / 3);
        min-width: 300px;
      }

      &.s-4 {
        flex-basis: calc(100% / 4);
        min-width: 300px;
      }

      &.s-5 {
        flex-basis: calc(100% / 5);
        min-width: 250px;
      }
    }

    .MuiSelect-select {
      padding-left: 0;
    }
  }

  .cursor-auto {
    cursor: auto !important;
  }

  .staticField {
    margin-top: 13px;
    line-height: 24px;
    width: 100% !important;
    height: auto;
    display: block;
    position: relative;
    background-color: transparent;
    font-family: Roboto, sans-serif;

    .label {
      line-height: 140%;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      z-index: 1;
      color: rgba($dark-color, 0.5);
      font-size: 14px;
      width: 100%;
      position: relative;
      border-bottom: 1px solid $light-dark-color;

      &--withInfo {
        display: flex;
        align-items: center;
      }

      &__status {
        margin: 0 0 5px 0;
      }

      .subLabel {
        width: 100%;
        clear: both;
        display: block;
        color: rgba($dark-color, 0.2);
        margin-top: 2px;
      }

      &.corousel-component {
        max-height: none;
      }
    }

    .carousel-icons {
      margin: 10px 0;
    }

    .carousel-icon {
      height: 64px;
      width: 64px;
      border-radius: 4px;
      background: rgba($dark-color, 0.04);

      &:hover,
      &:focus {
        background: rgba($dark-color, 0.1);
      }
    }

    .carousel-icon + .carousel-icon {
      margin-left: 8px;
    }

    .carousel-images {
      margin: 10px 0;
      display: flex;

      .carousel-image {
        height: 64px;
        width: 64px;
        border-radius: 4px;
        border: 2px transparent solid;
        box-sizing: border-box;
        background: rgba($dark-color, 0.04);
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.selected {
          border-color: $light-dark-color;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &:hover,
        &:focus {
          background: rgba(55, 70, 73, 0.1);
        }
      }
    }

    .image-preview {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      &::before {
        content: '';
        display: block;
        background-color: #fff;
        position: absolute;
        width: 64px;
        height: 64px;
        right: 0;
        bottom: 0;
        border-radius: 4px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .carousel-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        border: 1px $light-dark-color solid;
        box-sizing: border-box;
      }
    }

    .value {
      min-height: 20px;
      font-size: 14px;
      word-wrap: break-word;
      padding: 0;
      position: relative;
      width: 100%;
      border: none;
      outline: none;
      background-color: rgba(0, 0, 0, 0);
      color: $dark-color;
      line-height: 20px;
      font-family: inherit;
      height: 100%;
      box-sizing: border-box;
      margin-top: 3px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      .smaller {
        font-size: 12px;
        text-transform: none !important;
      }
    }
  }

  .capitalize-only-first-letter {
    .nested-rows-container {
      .nested-row {
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .items-with-shadow {

    & > .staticField,
    & > .contents,
    & > .render-charger-details-google-autocomplete {
      display: block;
      margin: 0 -24px !important;
      padding: 8px 24px;
      border-radius: 4px;
    }

    .date-picker-container .calendarIcon {
      right: 0 !important;
      top: 0 !important;
    }
  }

  .MuiInputBase-multiline {
    margin-top: 9px;
  }

  .MuiFormHelperText-root.Mui-error {
    margin-left: 0;
  }
}


.charger-details-modal {
  min-height: 60dvh;
  position: relative;
  border-top: 1px solid $light-dark-color;
  border-bottom: 1px solid $light-dark-color;

  .toolbar-separator {
      display: none;
  }

  // rewriting MUI for mobile
  .MuiDialog-container {
    @media #{$mobile-only} {
      &:after {
        display: none;
      }
    }

    .MuiPaper-root {
      @media #{$mobile-only} {
        display: flex;
        flex-direction: column;
      }
    }
  }

  #createEditChargerForm {
    min-height: unset;

    & > div:not(.charger-details-tabs),
    & > #chargerConfiguration {
      max-height: calc(100dvh - 287px); /* 287px - height of charger modal except content height and top-bottom paddings */

      @media #{$mobile-only} {
        max-height: none;
      }
    }

    .charger-details-tab,
    .charger-state-tab {
      background-color: #fff;
    }

    #chargerTelemetry {
      min-height: unset;
      height: calc(100% - 48px);
      overflow-x: hidden;
      overflow-y: auto;
    }

    .charger__telemetry_content {
      overflow-y: unset;
      height: unset;
    }

    .charger-configuration {
      height: 100%;
    }

    .pagination-bottom {
      @media #{$mobile-only} {
        position: static;
      }
    }

    .ReactTable.sticky {
      @media #{$mobile-only} {
        margin-top: 0;
      }

      .rt-table {
        @media #{$mobile-only} {
          bottom: auto;
        }
      }
    }
  }

  .content-wrapper {
    @include listWrapper;

    display: flex;
    flex-direction: column;
    bottom: 1px;

    #createEditChargerForm {
      @include listWrapper;
      height: 100%;
    }
  }

  .create-edit-static-list {
    height: unset !important;
    position: absolute;
    top: 49px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.MuiDialogContent-root {
    display: flex;

    @media #{$tablet-and-phone} {
      overflow: hidden;
    }
  }
}

.carousel {
  .thumbs-wrapper {
    .thumbs {
      padding: 0 !important;
    }
  }

  ul.control-dots {
    padding-left: 0 !important;
  }
}

.map-dialog-container {
  height: 60dvh;
  width: 100%;

  @media #{$mobile-only} {
    height: 90dvh;
  }
}

.map-dialog-confirm-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  padding: 16px 0;
}

.pac-container {
  z-index: 999999999999 !important;
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-size: 13px;
  font-weight: 300;
}

.pac-input {
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-left: 5px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;

  z-index: 1400;
  position: absolute;
  top: 12px;
  left: 23px;

  @media #{$tablet-and-phone} {
    width: 87% !important;
  }
}

.pac-input:focus {
  border-color: #398d74 !important;
}

.render-charger-details-google-autocomplete {
  top: 0 !important;
}

.configurationStatus__text {
  font-size: $font-size-12;
  color: $error-color;
  font-weight: bold;
}

.MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
