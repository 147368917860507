@import '../../styles/mediaQueriesConstants';
@import '../../styles/fonts';
@import '../../styles/_colors';

.navbar-hover-menu {
    margin-left: 4px !important;
    transition: margin-left 2s ease;

    .MuiList-root {
        background: #004A4E;
        color: #fff;
        box-sizing: border-box;
        border-radius: 0 5px 5px 0;
        box-shadow: none;
        padding: 5px !important;
        min-height: 52px;

        .MuiMenuItem-root {
            color: #fff;
            border-radius: 5px;
            padding: 0 5px;
            margin-top: 5px;
            height: 25px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.72px;
            font-size: $font-size-12;
            transition: all ease .2s;

            &:first-child {
                margin-top: 0;
            }

            &.selectedNavbarMenuItem {
                color: $primary-green-color;
                background: #fff !important;
            }

            &:hover {
                background: $primary-green-color !important;
                color: #fff !important;
            }
        }
    }

    &.submenu {
        .MuiList-root {
            border-radius: 5px;
        }
    }

    &.totop {
        .MuiList-root {
            padding: 10px !important;
        }
    }
}

.sub-title {
    margin-top: 5px;
    font-weight: 700;
    letter-spacing: 0.6px;
    font-size: $font-size-10;
    border-bottom: 1px solid #fff;
    width: 100%;
    text-transform: capitalize;
}

.MuiMenuItem-root + .sub-title {
    margin-top: 10px;
}

.profile-menu {
    width: 100%;

    .greetings {
        display: block;
        font-family: Roboto;
        font-size: $font-size-12;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.72px;
        margin: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .roles-switcher {
        margin: 20px 0 0 0;

        .MuiListItemText-root {
            .MuiTypography-root {
                font-size: $font-size-12;
            }
        }

        .MuiButtonBase-root {
            &.MuiMenuItem-root {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .sub-title {
        font-weight: 700;
        letter-spacing: 0.6px;
        font-size: $font-size-10;
        border-bottom: 1px solid #fff;
        width: 100%;
        text-transform: capitalize;
        margin-bottom: 7px;
    }
}

.navbar {
    .MuiDrawer-paper {
        overflow-x: hidden;
        border-right: none !important;
    }

    &.desktop {
        .MuiDrawer-paper {
            height: calc(100% - #{$footer-height - 1});

            @media #{$tablet-and-phone}{
                height: calc(100% - #{$footer-mobile-height});
            }
        }
    }

    .main-button {
        margin-top: 8px;

        &,div,path {
            transition-property: background, color;
            transition-timing-function: linear;
            transition-delay: 0s;
            transition-duration: 0.2s;
        }

        &.totop {
            margin-top: 0;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:first-child {
            margin-top: 0;
        }

        .main-button-text .MuiTypography-root {
            text-transform: uppercase;
            color: #fff;
            font-family: Roboto;
            font-size: $font-size-15;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .main-button-icon {

            svg {
                font-size: 32px;
            }
        }

        &.Mui-selected {
            background: #fff;
            color: $primary-green-color;

            .MuiListItemIcon-root {
                color: $primary-green-color;
            }

            .main-button-text .MuiTypography-root{
                color: $primary-green-color;
            }
        }

        &.hovered,
        &.expanded.has-submenu {
            background: #004A4E !important;
            color: #fff;
            position: relative;

            .MuiListItemIcon-root {
                color: #fff;
            }

            &.mini.has-submenu {

                border-radius: 4px 0 0 4px;


                @keyframes opacityShowing {
                    0% {
                    opacity: 0;
                    }

                    100% {
                    opacity: 1;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    background: inherit;
                    top: 0;
                    height: 100%;
                    right: -20px;
                    width: 20px;

                    animation: opacityShowing .2s ease;
                }
            }

            .main-button-text .MuiTypography-root{
                color: #fff;
            }
        }
    }

    .collapsed-menu {
        .MuiListItemButton-root {
            color: #fff;
            border-radius: 5px;
            padding: 0 5px;
            margin-top: 5px;
            height: 25px;
            transition: all ease .2s;

            .MuiTypography-root {
                font-family: Roboto;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.72px;
                font-size: $font-size-12;
            }

            &:first-child {
                margin-top: 0;
            }

            &.Mui-selected {
                color: $primary-green-color;
                background-color: #fff !important;
            }

            &:hover {
                background: $primary-green-color !important;
                color: #fff !important;
            }
        }

        &.totop {
            padding: 10px 6px;

            .roles-switcher {
                margin-top: 0;
            }
        }

        .MuiListItem-root.custom-component {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    overflow: hidden;
    position: relative;
    margin-top: 7px;
    margin-bottom: 28px;

    img {
        max-width: 100%;
        width: auto;
        position: absolute;
        margin: 0 auto;
    }

    .big-logo {
      height: 100%;
      opacity: 1;
    }

    .small-logo {
      height: 40px;
      opacity: 0;
    }
}
