.transfer-customer-list {
    list-style-type: none; 
    margin: 0;

    & > li {
        text-indent: -5px;
    }

    & > li:before {
        content: "-";
        text-indent: -5px;
        margin-right: 5px;
    }
}

.transfer-dialog-actions-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button {
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }
    }
}
