@import "common/styles/mediaQueriesConstants";
@import "common/styles/mixins";
@import "common/styles/_colors";
@import "screens/HomePage/_styles";
@import "screens/Dashboard/_styles";
@import "screens/ChargersList/_styles";
@import "screens/PlazaView/_styles";
@import "common/styles/_navbar";
@import "screens/Dashboard/components/Markers/_styles";
@import "screens/Dashboard/components/RapidSearch/_styles";
@import "screens/ChargersList/components/PersonalizeModal/_styles";
@import "screens/Login/_styles";
@import "screens/Profile/_styles";
@import "common/styles/_footer";
@import "screens/AdminTools/_styles";
@import "screens/ChargerDetails/_styles";
@import "common/styles/_pagination";
@import "common/styles/_statuses";
@import "screens/AdminTools/components/Dictionary/_styles";
@import "common/styles/_form";
@import "common/styles/_confirmationDialog";
@import "common/styles/_renderImageLoader";
@import "common/styles/_page404";
@import "common/styles/_tooltipWrapper";
@import "common/styles/_reactTable";
@import "common/components/TimeFormat/_styles.scss";
@import "screens/Reports/styles";
@import "screens/PlazaView/components/LogEvents/_styles";
@import "screens/PlazaView/components/Graphs/_styles";
@import "screens/AdminTools/components/ParametersSchema/_styles.scss";
@import "screens/ChargerDetails/components/ChargerConfiguration/_styles.scss";
@import "screens/PlazaView/components/PlazaChargerList/_styles";
@import "screens/PlazaView/components/EVList/_styles";
@import "screens/PlazaView/components/PlazaDetailsForm/_styles";
@import "screens/PlazaView/components/Demo/_styles";
@import "screens/ChargersList/components/DetailsUpdateModal/_styles";
//@import "screens/Dashboard/_sessionMap";
@import "screens/PlazaView/components/Editor/legacy/_styles";

html {
  scroll-behavior: smooth;
  overflow: hidden;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background: $scroll-color;
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $dark-color;
}

.tableHeaderWithTooltip {
  position: relative;

  &__title {
    position: relative;
  }

  &__title {
    top: -6px;
  }
}

*:focus {
  outline: none;
}

body, html {
  height: 100%;
}

a {
  text-transform: none !important;
}

h1 {
  font-size: 24px;
  font-weight: 300;
}

h2 {
  font-size: 18px;
  font-weight: 300;
}

button > div > span {
  text-transform: none !important;
}

.uppercase {
  text-transform: uppercase;
}

.noselect {
  user-select: none;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

  .main-content-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;

    &.mobile {
      flex-direction: column;
    }
  }

  #root-child {
    @extend #root;

    #content {
      display: flex;
      flex-grow: 1;
      overflow: auto;

      .content-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .charger-details-snackbar {
          bottom: 10dvh !important;
          text-align: center;
        }

        @media #{$mobile-only} {
          .MuiToolbar-root {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }
    }
  }

  #main {
    height: 100%;
    display: table-row;
    position: relative;
    background: #F2F5F8;
  }

  .MuiButton-root {
    .MuiSvgIcon-root {
      margin-right: 10px;

      &.no-text {
        margin-right: 0;
      }
    }
  }
}

.MuiDialog-container, #root {
  .MuiButton-root {
    line-height: normal;
    min-width: 120px;
    height: 40px;
    text-transform: uppercase;
  }

  .MuiPickersModal-dialog {
    padding-top: 0 !important;

    .MuiButton-root {
      min-width: 16px;
      height: auto;
    }
  }
}


.red-note {
  color: $error-color;
  font-size: small;
  line-height: 18px;
}

.infoButton__list > li {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.gray-note {
  color: rgba(0, 0, 0, 0.62);
  font-size: small;
  line-height: 18px;
  padding-left: 25px;
  margin-top: -18px;
}

.required-asterisk {
  color: $error-color;
}

.MuiFormLabel-asterisk {
  color: $error-color !important;
}

.required-asterisk {
  color: $error-color;
}

.MuiFormLabel-asterisk {
  color: $error-color !important;
}

.full-width-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-button {
  @media #{$tablet-and-phone} {
    min-width: 40px !important;
    margin: 10px !important;

    svg {
      margin: 0 !important;
    }
  }
}

.back-button {
  min-width: auto !important;

  .MuiSvgIcon-root {
    margin-right: 0 !important;
  }
}

.table-button-with-span {
  @media #{$tablet-and-phone} {
    min-width: 40px !important;
  }
}

.capitalize-first-letter:first-letter {
  text-transform: uppercase;
}

.capitalize-first-letter {
  display: inline-block;
  text-transform: lowercase;
}

.capitalize-only-first-letter:first-letter {
  text-transform: uppercase;
}

.toolbar-group {
  display: flex;
  align-items: center;
  gap: 8px;

  .table-button, .table-button-with-span {
    margin-right: 10px;
  }

  .table-button + .toolbar-separator,
  .table-button-with-span + .toolbar-separator,
  .table-button-with-span-wrapper + .toolbar-separator {
    margin-left: 6px;
  }

  .page-label {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .action-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.toolbar-separator {
  background-color: $table-border-color;
  display: block;
  height: 40px;
  margin-left: 16px;
  margin-right: 16px;
  width: 1px;
}

.full-width-border {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0.7;
}

.select-big-icon {
  button svg {
    width: 30px !important;
    height: 30px !important;
  }
}

.text-center {
  text-align: center;
}

.link {
  color: $dark-color;
  cursor: pointer;
  font-size: $font-size-14;
  text-decoration: underline solid;

  &:hover,
  &:focus {
    color:$primary-color;
    text-shadow: 0 0 1px $primary-color;
  }

  &.centered {
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }

  &[disabled] {
      color: rgba(55, 70, 73, 0.5);
  }
}

// to remove google icons and other rubbish stuff
.gm-style-cc {
  display: none !important;
}

.gm-style a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.no-padding {
  @media (max-device-width: 1224px) {
    padding: 0 !important;
    & > div > div {
      @media (orientation: portrait) {
        transform: translate(0px, 5dvh) !important;
      }
      @media (orientation: landscape) {
        transform: translate(0px, 0px) !important;
      }
    }
  }
}

.hover-drop-down {
  width: 192px;

  .MuiList-root {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
    border-radius: 2px;
  }
}

.MuiAppBar-root {
  z-index: 1 !important;
}

.expand-icon {
  transform: rotate(0deg);
  transition: 0.2s !important;
}

.expand-icon.opened {
  transform: rotate(90deg);
}

.MuiSvgIcon-root {
  @media  #{$tablet-and-phone}{
    margin: 0 !important;
  }
}

.MuiDialog-root {
  @media  #{$tablet-and-phone}{
    z-index: 999 !important;
  }
}

.pagination-bottom {
  background-color: rgb(255, 255, 255);

  @media  #{$tablet-and-phone}{
    & > * {
      font-size: 11px !important;
    }
  }
}

.MuiTab-root {
  @media  #{$tablet-and-phone}{
    padding: unset !important;
    line-height: 14px !important;
  }
}

.z-index-1300 {
  z-index: 1300 !important;
}

.tippy-popper {
  max-width: 80vw !important;
}

.contents {
  display: contents;
}

.action-error {
  font-size: 16px;
  color: #ff0000;
}

.DayPicker_portal__vertical {
  padding-top: 30px;
}
