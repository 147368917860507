$borderColor: #5B5B5B;

.battery-indicator {
  position: absolute;
  top: 270px;
  z-index: 1;
  right: 450px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 700;
  color: #728fc3;
  border-radius: 4px;
  pointer-events: none;

  &::after {
    position: absolute;
    bottom: -10px;
    width: 100%;
    height: 10px;
    background-color: $borderColor;
    content: '';
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &::before {
    position: absolute;
    top: -10px;
    width: 100%;
    height: 10px;
    background-color: $borderColor;
    content: '';
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .battery-container {
    height: 21dvh;
    border: 2px solid #5B5B5B;
    width: 8dvh;
    background: #e9e9e9;
    position: relative;

    .battery-percentage {
      height: 20%;
      position: absolute;
      bottom: 0px;
      background: #72bcc4;
      width: 100%;
      transition: height 2s linear;

      .percent {
        position: absolute;
        top: -11px;
        left: -68px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 66px;
      }
    }
  }

  .percent-lines {
    position: absolute;
    height: 100%;
    width: 10%;
    right: 0;

    .line {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $borderColor;

      &:nth-child(1) {
        top: 10%;
      }

      &:nth-child(2) {
        top: 20%;
      }

      &:nth-child(3) {
        top: 30%;
      }

      &:nth-child(4) {
        top: 40%;
      }

      &:nth-child(5) {
        top: 50%;
      }

      &:nth-child(6) {
        top: 60%;
      }

      &:nth-child(7) {
        top: 70%;
      }

      &:nth-child(8) {
        top: 80%;
      }

      &:nth-child(9) {
        top: 90%;
      }
    }
  }

  .blink {
    position: absolute;
    width: 40%;
    height: calc(100% - 4px);
    left: 3px;
    bottom: 2px;
    background-image: linear-gradient(90deg, transparent, transparent 20%, rgba(255, 255, 255, 0.21) 40%, transparent 100%);
  }

  .top-connector {
    position: absolute;
    top: -10px;
    background: $borderColor;
    height: 20px;
    width: 34px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .header {
    display: flex;
    position: absolute;
    right: -91px;
    justify-content: space-between;
    width: 84px;
    align-items: center;
    height: 50%;
    top: 10%;

    .icon {
      height: 100%;

      img {
        height: 100%;
        max-height: 100%;
      }
    }

    .text {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: $borderColor;
    }
  }

  .flash {
    position: absolute;
    font-size: 25px;
    color: #fdc461;
    top: 15px;

    .MuiSvgIcon-root {
      height: 40px;
      width: 40px;
    }
  }

}
