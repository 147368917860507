.time-format {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;

  .MuiToggleButton-sizeSmall {
    height: 20px;
    font-size: 10px;
    min-width: 60px;
    padding: 1px 10px;
  }

  .MuiSelect-select {
    font-size: 12px;
    height: 15px;
    color: gray;
  }

  .MuiSelect-selectMenu {
    padding: 4px 24px 4px 7px;
  }

  .time-format-item {
    display: inline;

    div {
      display: inline;
      margin-right: 3px;
    }
  }
}

.time-format-item {
  display: flex;
  min-width: 100px;
}
