.page404-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .page404-message {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    &-bg {
      color: #f1f1f1;
      font-size: 178pt;

      @media (max-width: 414px) {
        font-size: 130pt;
      }
    }

    &-text {
      position: absolute;
      color: #2a736e;
      font-size: 19pt;

      @media (max-width: 414px) {
        font-size: 14pt;
      }
    }
  }
}