@use "sass:math";
///////////////////////////////   FONT_SIZE

$base-font-size: 16;

@function px-to-rem($size) {
  $pxUnitless: strip-unit($size);
  @return #{math.div($pxUnitless, 16)}rem;
}

// strips away any unit from a value: e.g. "16px" => 16
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

$font-size-9: px-to-rem(9);
$font-size-10: px-to-rem(10);
$font-size-11: px-to-rem(11);
$font-size-12: px-to-rem(12);
$font-size-13: px-to-rem(13);
$font-size-14: px-to-rem(14);
$font-size-15: px-to-rem(15);
$font-size-16: px-to-rem(16);
$font-size-18: px-to-rem(18);
$font-size-20: px-to-rem(20);
$font-size-21: px-to-rem(21);
$font-size-22: px-to-rem(22);
$font-size-24: px-to-rem(24);
$font-size-25: px-to-rem(25);
$font-size-30: px-to-rem(30);
$font-size-32: px-to-rem(32);
$font-size-40: px-to-rem(40);

///////////////////////////////   END
