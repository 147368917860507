// COMMON
$primary-color: #559178;
$primary-green-color: #275E63;
$dark-color: #374649;
$black-color: #002d3d;
$blue-color: #478ec1;
$light-dark-color: #e0e0e0;
$error-color: #E04E4E;
$grey-color: rgba(55, 70, 73, 0.5);

// SCROLL
$scroll-color: #d5d5d5;

// TABLE
$table-border-color: #E4E6E6;
$table-row-bgColor: #fcfcfc;
$table-hover-row: #ededed;

$yellow-color: #FF8A00; // yellow