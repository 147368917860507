.plaza-chargers-table {
  width: -webkit-fill-available;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  height: calc(100dvh - 268px);
  @media #{$mobile-only} {
    height: calc(100dvh - 208px);
    overflow: hidden;
  }

  .MuiToolbar-root {
    padding-left: 0;
  }

  .ReactTable {
    height: calc(100dvh - 324px);
    @media #{$mobile-only} {
      height: calc(100dvh - 287px);
    }
  }

  .no-chargers-label {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: fit-content;
    color: #c7c7c7;
    font-family: Roboto;
  }

  &.with-ev-list {
    height: calc(100dvh - 370px);

    .ReactTable {
      height: 100%;
    }
  }
}
