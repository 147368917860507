@import "../../common/styles/_mixins";

.list-wrapper {
  @include listWrapper;

  flex-grow: 1;

  .chargers-table {
    @include listGrid;

    &.fw-update-table {
      @media #{$mobile-only} {
        top: 100px;
      }
    }

    .ReactTable {
      height: 100%;
      font-size: 14px;

      .date-input-filter {
        width: 100%;
        height: 28px !important;
      }

      .rt-td:first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.chargers-toolbar {
  justify-content: space-between;

  .page-label {
    .title {
      font-weight: bold;
    }

    @media #{$tablet-and-phone} {
      flex-grow: 1;
      justify-content: center;

      .toolbar-separator {
        visibility: hidden
      }
    }
  }

  @media #{$tablet-and-phone} {
    .toolbar-group {
      flex-wrap: wrap;
      justify-content: center;

      .action-buttons {
        flex-wrap: wrap;
        margin: 10px 0;
        justify-content: center;
      }
    }
  }
}

.chargers-toolbar-mobile {
  flex-direction: column;

  &.MuiToolbar-root {
    padding-left: 0;
    padding-right: 0;
  }

  .toolbar-group {
    width: 100%;
  }

  .table-button, .table-button-with-span {
    margin: 10px 0 10px 10px !important;
  }

  .title {
    font-size: 12px;
    margin-left: 10px;
  }
}

.create-edit-static-list {
  height: calc(100dvh - 137px);
}

.table-material-text {
  font-size: 13px !important;
  height: 28px !important;
  background-color: white !important;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  hr {
    bottom: 0 !important;
    left: 1px !important;
    right: 1px !important;
    width: auto !important;
    border-radius: 5px !important;
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
  }

  &.autocomplete-filter {
    position: relative;

    svg {
      fill: $light-dark-color !important;
      transform: none !important;
    }

    .resetOwnerButton {
      position: absolute !important;
      top: 0;
      right: 5px;
    }

    .selected {
      position: absolute;
      top: 6px;
      left: 3px;
      right: 37px;
      bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table-multi-select-container {
  display: flex;
  width: fit-content;
}
