.dictionary-toolbar-btn {
  margin: 0 15px 0 0 !important;
}

.dictionary, .accounts-table {
  .smallIconContainer {
    width: 20px !important;
    height: 20px !important;
    padding: 0px !important;

    svg {
      width: 19px !important;
      height: 19px !important;
    }
  }
}

.dictionary-inactivate-modal {
  text-align: center;
  padding-top: 20px
}
