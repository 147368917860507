.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  //height: calc(100dvh - 47px);
  background-image: url("/images/Log_In_background_minified.jpg");
  background-size: cover;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @media (max-width: 736px) {
    background-image: url("/images/Log_In_background_mobile.jpg");
  }

  @media (max-width: 736px) and (orientation: landscape) {
    overflow: auto;
  }

  .login-paper {
    width: 20%;
    text-align: center;
    padding: 50px;

    @media (max-width: 1024px) {
      width: 45%;
    }

    @media (max-width: 736px) and (orientation: landscape) {
      padding: 20px 50px;
      width: 60%;
    }

    @media (max-width: 600px) and (orientation: portrait) {
      width: 75%;
    }
  }

  h3 {
    font-size: 16px;
    color: $primary-color;
    font-weight: 600;
  }

  .login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    height: 140px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
    }
  }

  .login-error {
    padding: 10px;
    margin-top: 10px;
    color: $error-color;
    font-weight: 600;
    transition: all 1s ease;
    max-height: 20px;
  }

  &.has-error {
    .login-error {
      max-height: 200px;
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-btns {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      justify-content: center;
    }

    .MuiButton-root {
      min-width: 130px;
    }

    .MuiButton-root + .MuiButton-root {
      margin-left: 15px;
    }

    .login-form-btns {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 25px;
    }

    .MuiTextField-root {
      margin-top: 14px;
    }

    .login-form-forgot-password {
      margin-top: 15px;
      font-weight: 700;
      text-decoration: none;
    }
  }
}

.loading-block-wrapper {
  position: relative;
  min-height: 60px;
  transition: all .5s ease;

  .loading-block {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -25px;
    right: -25px;
    top: -25px;
    bottom: -25px;
    background: rgba(255,255,255,0.4);
    z-index: 1;
    backdrop-filter: blur(3px);
  }
}

.external-auth-providers {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .label {
    font-size: 16px;
    color: $primary-green-color;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: calc(50% - 16px);
      right: 0;
      top: 50%;
      margin-top: -1px;
      height: 1px;
      background-color: $primary-green-color;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: calc(50% - 16px);
      left: 0;
      top: 50%;
      margin-top: -1px;
      height: 1px;
      background-color: $primary-green-color;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .item {
      display: flex;
      padding: 10px 15px;
      border: solid 1px #999;
      align-items: center;
      color: #333;
      text-decoration: none;
      cursor: pointer;
      margin-bottom: 15px;
      font-weight: normal;
      font-size: 15px;
      width: 100%;
      justify-content: center;
      box-sizing: border-box;

      img {
        margin-right: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
