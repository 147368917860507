$tablet-and-phone: "screen and (max-width: 1023px)";
$tablet: "((min-width: 768px) and (max-width: 1024px))";
$bigger-than-mobile: "(min-width: 768px)";
$mobile-only: "screen and (max-width: 767px)";
$mobile-portait-only: "screen and (max-width: 479px)";

$footer-height: 25px;
$footer-mobile-height: 20px;
$navbar-mobile-height: 47px;

$pagination-height: 56px;

$tooltipBackgroundColor: rgba(50, 50, 50, 0.7);
