.chargers-legend-container {
  overflow-y: hidden;

  .chargers-limit-message {
    color: #a3a3a3;
    font-size: 0.7rem;
    padding-bottom: 5px;
  }

  &.details.opened {
    max-height: calc(100dvh - 265px);
  }

  .charger-list-item {
    border-bottom: 1px solid $light-dark-color;
    height: 37px;
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 4px;
    padding: 3px;

    &:last-child {
      border-bottom: none;
    }

    &.no-id-item {
      display: inline-block;
      align-items: center !important;
      height: 30px;

      .charger-list-item-id {
        margin-left: 20px;
      }

      .charger-list-item-marker {
        top: unset;
      }
    }

    &.unselected {
      & > .charger-list-item-name, .charger-list-item-id {
        color: rgb(177, 177, 177) !important;
      }

      .charger-list-item-marker {
        background-color: rgb(177, 177, 177) !important;
      }
    }

    .charger-list-item-name {
      margin-left: 20px;
      font-size: 12px !important;
    }

    .charger-list-item-id {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .charger-list-item-marker {
      height: 9px;
      width: 9px;
      border-radius: 50%;
      position: absolute;
      top: 7px;
      left: 5px;

      &.no-id-item {
        position: inherit;
      }
    }
  }
}

.real-time-energy {
  &.chart-container {
    height: calc(100% - 105px);
  }
}

.tooltip-separator {
  border-bottom: 1px solid white;
  margin: 5px;
}

.empty-plaza-stub {
  position: absolute;
  font-family: Roboto;
  color: #b3b3b3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
