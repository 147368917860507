.parameters-configuration-container {
  height: calc(100% - #{$footer-height});

  .parameters-schema-container {
    overflow-y: scroll;
    height: 100%;

    .content {
      font-family: 'sans-serif';
    }
  }

  .missing-content {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.parameters-schema-form {
  .date-picker-container.file-loader {
    padding-right: 0px !important;
  }

  .MuiFormControl-root {
    width: 100%;
  }
}

.error-message {
  bottom: -2px;
  font-size: 12px;
  line-height: 12px;
  color: $error-color;
}