@import '../../common/styles/fonts';
@import '../../common/styles/colors';
@import '../../common/styles/mediaQueriesConstants';

$maxWidthContainer: 1400px;

#content:has(.home-page__wrapper) {
  background: #fafafa;
}

.home-page {
  &__wrapper {
    width: 100%;
    padding: 0 50px 20px;
    box-sizing: border-box;

    @media #{$mobile-only} {
      padding: 20px;
    }
  }

  &__title {
    max-width: $maxWidthContainer;
    font-size: $font-size-18;
    font-weight: 500;
    margin: 22px auto;
  }

  &__charts {
    max-width: $maxWidthContainer;
    margin: 0 auto;

    .chart {
      &__paper {
        box-sizing: border-box;
        padding: 10px;
      }

      &__header {
        .total__count {
          text-transform: capitalize;
          text-align: right;
          background-color: #efefef;
          border-radius: 4px;
          box-sizing: border-box;
          margin-left: auto;
          display: inline-block;
          padding: 3px;
          font-weight: 400;
          font-size: $font-size-12;
        }

        h2 {
          font-size: $font-size-20;
          font-weight: 400;
          flex: 1;
          margin: 0;
          padding: 0 5px 0 0;
          box-sizing: border-box;

          @media #{$tablet-and-phone} {
            font-size: $font-size-16;
          }

          .link {
            font-size: $font-size-20;
          }
        }
      }
    }

    &--common {
      gap: 20px;
      display: flex;
      margin-bottom: 20px;
      flex-wrap: wrap;

      .chart {
        &__paper {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        &__header {
          display: flex;
          min-height: 60px;

          .total__count {
            align-self: flex-start;
          }

          .subtitle {
            font-size: $font-size-12;
            color: rgba(0, 0, 0, 0.54);
          }
        }
      }
    }

    &--additional {
      column-count: 2;
      gap: 20px;

      @media #{$tablet-and-phone} {
        column-count: auto;
      }

      .chart {
        &__paper {
          max-height: 300px;
          break-inside: avoid;

          @media #{$tablet-and-phone} {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
        &__header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .total__count {
            margin: 0;
          }
        }
      }
    }
  }
}
