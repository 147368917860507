.batch-update {
  &__select-container {
    border: 1px solid $blue-color;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: $font-size-14;
    max-width: 320px;
  }

  &__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  &__button, &__link {
    flex: 1 0 auto;
    padding: 8px 16px;
    text-align: center;
  }

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: $blue-color;
    color: white;
    transition: background-color .3s;
    gap: 6px;

    &:hover {
      background-color: #417cb3;
    }
  }

  &__selected-file {
    font-size: $font-size-12;
    color: #808080;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;

    & > span {
      white-space: nowrap;
    }

    &--error {
      color: $error-color;
    }
  }

  &__file-name {
    color: #000;
  }

  &__table {
    margin-top: 32px;

    &-title {
      font-size: $font-size-14;
      color: #808080;
      margin-bottom: 16px;
    }

    &-body {
      padding: 6px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
    }

    &-row {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 0;
    }

    &-cell {
      display: flex;
      align-items: center;
      font-size: $font-size-14;

      &--name {
        gap: 5px;
        flex: 2;
      }

      &--date {
        overflow: hidden;
        white-space: nowrap;
        color: #808080;
        flex: 1;
        max-width: 260px;

        @media #{$mobile-only} {
          white-space: initial;
        }
      }

      &--status {
        min-width: 120px;
      }
    }
  }
}
