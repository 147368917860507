.expansion-panel-group {
  margin: 5px;

  .mui-expansion-panel {

    .status {
      margin-left: 10px;
      text-transform: uppercase !important;
    }

    .online-status {
      color: #9BD36D;
    }

    .offine-status {
      color: gray;
    }

    .MuiAccordionSummary-content {
      font-family: Roboto, serif;
      font-size: 15px;
      font-weight: 500;
      display: block;
      overflow-wrap: break-word;
      text-transform: uppercase;
      padding: 0;

      .online-timestamp {
        font-family: Roboto, serif;
        font-size: 10px;
        font-weight: 500;
        text-transform: none;
      }
    }

    .settings-action-button {
      width: 100%;
      margin-bottom: 5px;
    }

    .MuiAccordionDetails-root {
      & > div {
        width: 100%;
      }

      .details.opened {
        display: flex;
        flex-direction: column;
      }
    }

    .details {
      .values {
        width: 100%;
      }
    }

    .nodes {
      .root-nodes-item, .withTooltip {
        padding: 7px;
        margin-top: 3px;
        border-radius: 2px;
        color: #7d7d7d;
        font-weight: 700;
        font-size: 12px;
      }

      div {
        display: block !important;
        border-bottom: 1px solid #f0f0f0;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
