.static-charging-ev {
  position: absolute;
  top: 286px;
  height: 12dvh;
  width: 28vw;
  z-index: 1;
  left: 15%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #728fc3;
  user-select: none;
  pointer-events: none;

  .arrow-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    min-width: 100px;

    .arrow {
      width: 100%;
      height: auto;
    }
  }

  img {
    height: 100%;
    width: auto;
  }

}
