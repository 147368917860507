.status-cell {
  &__wrapper, & {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-top: 3px;
  }
}
