.-pagination {
  align-items: center;
  color: rgba(55, 70, 73, 0.5);
  background: rgba(55, 70, 73, 0.04);
  font-size: 14px;
  padding: 14px 44px !important;
  font-family: Roboto;

  @media (max-width: 375px) {
    font-size: 12px;
  }

  @media (max-width: 320px) {
    font-size: 10px;
  }

  & > div {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .-pageSizeOptions {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 !important;

    .pageSizeSelect {
      width: 80px !important;
      overflow: hidden;

      @media (max-width: 320px) {
        width: 70px !important;
      }

      & > div > div > div {
        color: #5B5B5B !important;
        font-size: 14px;

        @media (max-width: 375px) {
          font-size: 12px;
        }

        @media (max-width: 320px) {
          font-size: 10px;
        }
      }
    }

    .pageSizeSelect.mobile {
      width: 50px !important;
      font-size: 11px !important;

      & > div > div > div {
        overflow: visible !important;
        @media (max-device-width: 1224px) {
          padding-right: 22px !important;
        }
      }
    }

    .advanced-search {
      margin-left: 10px;
    }
  }

  .-pageJumpBlock {
    justify-content: flex-end;

    button {
      padding: 0 !important;
      margin: 0 5px;
      @media (max-device-width: 1224px) {
        width: 30px !important;
      }
    }
  }
}

.-pagination.dashboard-customized {
  justify-content: flex-end;

  .-pageJumpBlock, .-pageSizeOptions {
    flex-basis: unset;
  }
}

@media (max-device-width: 1224px) {
  .-pagination {
    padding: 3px 5px !important;
  }
}