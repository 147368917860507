.chart-container {
  display: grid;
  grid-template-columns: min-content 3fr;
  height: 100%;

  .chart-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: auto;
    padding-bottom: 10px;
  }
}

.chart-form-with-overflow-y {
  overflow-y: auto;
}

.select-field-item-tooltip {
  word-wrap: break-word;
  width: auto;
  max-width: 190px;
  font-size: 13px;
  text-align: left;

  .select-field-item-id {
    font-weight: 600;
  }
}

.chart-form {
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px;
  z-index: 2; //TODO not a great way to save box-shadow
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  width: 260px;

  .autocompleteWrapper {
    & > div > div > div > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.selected > div {
      width: 208px !important;
    }
  }

  @media #{$mobile-only} {
    width: 100%;
    box-shadow: unset;
    padding: 0;
  }

  .chart-select-field.clearable {
    width: 208px !important;
  }

  .autocompleteWrapper {
    width: 257px;
    margin-right: 10px;

    @media #{$mobile-only} {
      width: 100%;
      margin-right: 0;
    }
  }

  .submit-button {
    width: 100%;
    margin-top: auto;
  }

  .filter-item {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

    .DateRangePickerInput {
      width: 248px;
    }
  }

  .autocompleteWrapper {
    & > div > div > div > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.selected > div {
      width: 208px !important;
    }
  }

  .MuiFormControl-root {
    margin-top: 14px;
  }
}

.equidistant {
  *:not(:first-child) {
    min-height: 33px;
  }

  .chart-field-wo-top-margin {
    margin-top: 0;
  }
}

.chart-label {
  margin-top: 20px !important;
  display: block !important;
}

.chart {
  position: relative;
  margin-top: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  & > .MuiPaper-root {
    // Table
    &:not(:first-child) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
  }
}

.chart.left-bar {
  @media (min-device-width: 1224px) {
    position: absolute;
    left: 250px;
    right: 0;
  }
}

.label-left {
  font-weight: 500;
  font-size: 18px;

  @media (max-device-width: 1224px) {
    font-size: 18px;
    margin-top: 12px
  }
  margin-left: 20px;
  margin-top: 20px;
  display: block;
}

@media #{$tablet-and-phone} {
  .DayPicker_focusRegion {
    & *, *:before, *:after {
      box-sizing: inherit;
    }
  }

  .DateRangePicker_picker {
    z-index: 1800;
  }

  .DayPickerNavigation_button {
    box-sizing: border-box;
    text-align: center !important;
  }
}

.DateRangePickerInput_arrow {
  width: 12px;
  height: 23px;
  border-right: 2px solid #CCCCCC;
  color: transparent;
}

.DateInput_input {
  color: rgb(51, 51, 51) !important;
  opacity: 0.7;
  text-align: center;
  padding-left: 15px;
}

.DateInput:before {
  content: '';
  background: url('/images/calendar-icon.svg');
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: inline-block;
}

.DateInput__small {
  width: 106px;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #CCCCCC;
}

.DateRangePickerInput_calendarIcon {
  padding: 7px !important;
  margin-left: 15px !important;
}

.labeled-form-field {
  .staticField {
    padding-top: 13px !important;
    padding-bottom: 8px !important;
    line-height: 24px;
    width: 100% !important;
    height: auto;
    display: block;
    position: relative;
    background-color: transparent;
    font-family: Roboto, sans-serif;

    .label {
      width: 100%;
      position: relative;
      padding-bottom: 1px;

      .text {
        color: rgba(0, 0, 0, 0.54);
        line-height: 22px;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        font-size: 14px;
        padding-bottom: 5px;
      }
    }
  }
}

.labeled-form-field.bottom-line {
  padding-bottom: 1px;
  border-bottom: 1px solid $light-dark-color;
}

.duration-picker.bottom-line {
  padding-bottom: 10px;

  &.error {
    border-color: $error-color;
    border-bottom-width: 2px;
  }
}

.duration-picker {

  .duration-input {
    margin-top: unset !important;
  }

  &:not(.energy) {
    .duration-input {
      width: 16% !important;
    }
  }

  &.energy {
    .duration-input {
      width: 90px !important;
    }
  }

  .from-to-container {
    display: inline;
    position: relative;

    div.duration-input:nth-child(3) {
      margin-left: 8px !important;
    }


    .hrs-span, .min-span {
      position: absolute;
      margin: 0 auto;
      left: 3%;
      top: 14px;
      color: #333333;
      opacity: 0.7;
      font-size: 11px;
      font-weight: 500;
    }

    .min-span {
      left: 57%;
    }
  }

  .error {
    font-size: 11px;
    font-weight: 500;
    color: $error-color
  }
}


.chart-range-field {
  .staticField {
    padding-top: 13px !important;
    padding-bottom: 8px !important;
    line-height: 24px;
    width: 100% !important;
    height: auto;
    display: block;
    position: relative;
    background-color: transparent;
    font-family: Roboto, sans-serif;

    .label {
      width: 100%;
      position: relative;
      padding-bottom: 1px;

      .text {
        color: rgba(0, 0, 0, 0.54);
        line-height: 22px;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        font-size: 18px*0.75;
      }
    }
  }
}

.fw-versions-chargers .ReactTable {
  flex-grow: 1;

  @media (min-device-width: 1224px) {
    height: calc(34dvh - 32px);
  }
}

.plaza-chart-per-hour {
  .echarts-for-react, .ReactTable {
    width: 100%;
    min-height: 340px;
    height: 30dvh;

    @media (max-device-width: 1224px) {
      height: 300px;
    }
  }
}

.table-custom-pagination {
  .-pageSizeOptions {
    flex-basis: 62%;

    .table-button {
      margin-left: 20px;
    }
  }

  .-pageJumpBlock {
    flex-basis: 38%;
  }

  @media #{$tablet-and-phone} {
    .-pageSizeOptions {
      flex-basis: 41%;
    }

    .-pageJumpBlock {
      flex-basis: 59%;
    }
  }
}

.faults-filter-cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break-string {
  white-space: normal !important;
  word-break: break-word !important;
}

.chart-toggle {
  position: absolute;
  right: 15px;
  top: 15px;

  .MuiToggleButton-sizeSmall {
    height: 35px;
  }

  .MuiToggleButtonGroup-groupedSizeSmall {
    padding: 0 5px 0 4px;
  }

  @media (max-width: 1224px) {
    right: 5px;
    top: 5px;

    .MuiSvgIcon-root {
      font-size: 18px;
    }

    .MuiToggleButton-sizeSmall {
      height: 28px;
    }
  }
}

.chart-title {
  padding: 5px 100px 25px 5px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: left;

  @media (max-width: 1224px) {
    font-size: 14px;
  }

}

.MuiAutocomplete-option {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
}

.MuiAutocomplete-input.Mui-disabled {
  color: silver !important;
}

.date-time-picker {
  .MuiInput-underline:before {
    border-bottom: 1px solid $light-dark-color;
  }
}
