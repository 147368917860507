.i2p2-nav-bar {
  white-space: pre;

  @media #{$tablet-and-phone} {
    background: #034157; /* For browsers that do not support gradients or mobile devices (theme)*/
    height: $navbar-mobile-height;
    padding: 0;

    & > h1:first-of-type {
      height: $navbar-mobile-height !important;
      line-height: $navbar-mobile-height !important;
    }
  }

  background: -webkit-linear-gradient(90deg, #1E4D64, #548F76); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(90deg, #1E4D64, #548F76); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(90deg, #1E4D64, #548F76); /* For Firefox 3.6 to 15 */
  background: linear-gradient(90deg, #1E4D64, #548F76); /* Standard syntax */

  .navbar-logo-container {
    min-width: 200px;
    height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    .logo-img {
      max-width: 200px;
      max-height: 47px;
      object-fit: contain;
    }
  }

  .navbar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    height: 75px;

    a {
      padding: 0 16px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: rgba(153, 153, 153, 0.2);
      color: #fff;

      a {
        color: #fff;
      }
    }

    &.active {
      background-color: rgba(255, 255, 255, 0.2);

      a, div {
        color: #fff;
      }
    }
  }

  .greetings {
    margin-right: 10px;
    color: white;
  }
}

.logo-mobile {
  width: 80%;
  margin: 10%;
}

.i2p2-nav-center {
  flex-grow: 1;
  left: 300px;
  right: 150px;
  display: flex;
  overflow: hidden;
  padding-left: 24px;
}

.i2p2-nav-tabs {
  width: 300px;

  > div a div:hover {
    color: white;
  }
}

@media (max-device-width: 1224px) {
  .i2p2-nav-left {
    flex-grow: 1;
  }
}

.powered-by-container {
  width: 200px;
  height: 48px;
  display: flex;

  div.text {
    line-height: 50px;
    font-family: Roboto;
    font-weight: bold;
    color: white;
    width: 90px;
  }

  img {
    width: 78px;
  }
}

.company-logo {
  background-image: url("/images/company-logo.png");
  width: 310*0.7px;
  height: 65*0.7px;
  background-size: 310*0.7px 65*0.7px;
  border-radius: 5px;
}

.iotecha-logo {
  background-image: url("/images/iotecha-logo.svg");
}

.navbar-right {
  display: flex;

  .authenticated-wrapper {
    display: flex;
    align-items: center;
    color: white;

  }
}

.selectedNavbarMenuItem {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.nestedNavbarMenuItem {
  padding-left: 30px !important;
}
