$charger-node-color: #72bcc4;
$storage-node-color: #2f5944;
$hard-constraint-node-color: #728fc3;
$soft-constraint-node-color: #fdc461;
$junction-node-color: #67224e;
$load-node-color: #8EA6B2;
$measurer-node-color: #76709E;
$renewable-with-storage-node-color: #589b78;
$renewable-node-color: #9ec771;
$grid-node-color: #a1457e;

$charging-policy-node-color: #a1a0ea;

$node-connector-color: $junction-node-color;
