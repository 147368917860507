@import "../../../../common/styles/mediaQueriesConstants";

.charger-details-modal {
  .MuiDialogTitle-root {
    padding: 12px 24px 12px 24px;
  }

  &.MuiDialogContent-root {
    padding: 0;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    font-size: 18px !important;
    font-weight: 500;
    margin: 0;

    @media #{$mobile-only} {
      flex-direction: column;
      justify-content: center;
    }
  }

  .title-container-title {
    display: flex;
    align-items: center;

    @media #{$mobile-only} {
      margin-bottom: 5px;
    }
  }

  .charger-configuration {
    height: calc(100dvh - 298px);
  }

  .fw-packages-content {
    padding: 8px 24px;
  }
}
