.clock {
  zoom: 0.4;
  position: absolute;
  top: -23px;
  left: 30%;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: white;
  margin: 100px auto 0px auto;
  border: 10px solid #728fc3;
  z-index: 1;
  box-shadow: #5B5B5B 0 0 10px;
  font-family: Roboto, sans-serif;
  pointer-events: none;
}

.center {
  background-color: #728fc3;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 20;
  box-shadow: #5B5B5B 0 0 10px;
}

.hourHand {
  width: 10px;
  height: 75px;
  background-color: #728fc3;
  transform-origin: bottom center;
  border-radius: 4px;
  position: absolute;
  top: 75px;
  left: 145px;
  z-index: 10;
}

.minuteHand {
  width: 5px;
  height: 120px;
  background-color: #728fc3;
  transform-origin: bottom center;
  border-radius: 4px;
  position: absolute;
  top: 30px;
  left: 147px;
  z-index: 9;
}

.clock ul {
  list-style: none;
  padding: 0;

}

.clock ul li {
  position: absolute;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  color: #a1457e;
}

.clock ul li:nth-child(1) {
  right: 22%;
  top: 8.5%;
}

.clock ul li:nth-child(2) {
  right: 6%;
  top: 25%;
}

.clock ul li:nth-child(3) {
  right: 1%;
  top: calc(50% - 10px);
  color: #5b5b5b;
  font-size: 30px;
  font-weight: bold;
}

.clock ul li:nth-child(4) {
  right: 6%;
  top: 69%;
}

.clock ul li:nth-child(5) {
  right: 22%;
  top: 84%;
}

.clock ul li:nth-child(6) {
  right: calc(50% - 10px);
  top: calc(99% - 20px);
  color: #5b5b5b;
  font-size: 30px;
  font-weight: bold;
}

.clock ul li:nth-child(7) {
  left: 22%;
  top: 84%;
}

.clock ul li:nth-child(8) {
  left: 6%;
  top: 69%;
}

.clock ul li:nth-child(9) {
  left: 1%;
  top: calc(50% - 10px);
  color: #5b5b5b;
  font-size: 30px;
  font-weight: bold;
}

.clock ul li:nth-child(10) {
  left: 6%;
  top: 25%;
}

.clock ul li:nth-child(11) {
  left: 22%;
  top: 8.5%;
}

.clock ul li:nth-child(12) {
  right: calc(50%);
  top: 2%;
  color: #5b5b5b;
  font-size: 30px;
  font-weight: bold;
}
