.carousel-slider {
  li.slide {
    display: inline-flex !important;
    justify-content: center !important;
    align-items: stretch !important;
  }
}

.carousel {
  .thumbs-wrapper {
    margin: 2px !important;
  }
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 99999999999 !important;
}

.image-upload-button {
  display: none;
}