@import url('https://fonts.googleapis.com/css?family=Oleo+Script');
@import "energyConnector";
@import "variables";
@import "expansionPanel";

.editor-container {
  position: relative;
  display: grid;
  grid-template-columns: min-content 3fr;
  height: calc(100% - 112px);

  @media #{$mobile-only} {
    height: calc(100% - 145px);
    overflow: auto;
    position: relative;
  }

  &.full-width {
    grid-template-columns: auto;
  }

  .minimap-container {
    overflow: auto;

    @media #{$mobile-only} {
      position: relative;
    }

    .minimap {
      right: unset; // turning off default styles
      background-color: rgba(0, 0, 0, 0.08);
      border-color: #D0D0D0 !important;
      z-index: 21;
      @media #{$mobile-only} {
        position: absolute !important;
      }

      .minimap-viewport {
        background-color: rgba(0, 0, 0, 0.1) !important;
        border: 1px solid #D0D0D0 !important;
      }
    }
  }

  .map-container {
    position: relative;
    background-color: #ffffff;
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;

    &.show-energy-flow {
      .energy-connector-direction, .energy-connector {
        display: block !important;
      }
    }

  }

  .graph-container {

    &.show-phases {
      .topology-phases {
        display: block !important;
      }
    }

    &.empty {
      &:after {
        content: 'No Plaza found'; // 'There is no active Plaza here. Create Plaza structure first.';
        position: absolute;
        font-family: Roboto;
        color: #b3b3b3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .no-selected {
    width: 280px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;

    .form-hint {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;

      .arow-icon {
        height: 85px;
        width: 82px;
        background-size: contain;
        background: url('/screens/PlazaView/resources/No_selected.svg') no-repeat;
      }

      .form-hint-text {
        color: #b7b7b8;
        font-size: 21px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .editor-form {
    grid-column: 1;
    grid-row: 1;
    padding: 0;
    z-index: 1;
    position: relative;
    width: 280px;
    flex-flow: column wrap;
    float: left;
    -ms-flex-align: start;
    align-items: start;
    overflow-y: auto;
    overflow-x: hidden;

    .column {
      margin-left: -8px;
      margin-right: -8px;
      margin-top: -8px;
    }

    .autocompleteWrapper {
      & > div > div > div > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .resetOwnerButton {
        bottom: -14px;
      }

      &.selected > div {
        width: 215px !important;
      }
    }

    .sidebar {
      height: 100%;
      position: relative
    }

    .disabled {
      & input {
        color: #a7a7a7 !important;
        text-transform: capitalize; // temp
      }
    }

    .editor-buttons-container {
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      display: flex;
      justify-content: space-between;

      .cancel-button, .submit-button {
        width: 125px;
      }
    }
  }

  .editor-content {
    background-color: #fff;
    overflow: auto;
    padding: 20px;
    position: relative;
    @media #{$mobile-only} {
      min-width: 700px;
      position: relative;
    }

    &.editor-fetching {
      &:after {
        content: '';
        background-color: rgba(0, 0, 0, 0.14);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .orgchart {
      background-image: unset;
      width: 100%;
      height: 100%;

      table {
        margin: auto;
      }

      .lines {
        * {
          border-color: darkgray;
        }

        .downLine {
          background-color: darkgray;
        }
      }

      .ghost-node {
        & > rect {
          display: none;
        }

        background: url('/screens/PlazaView/resources/drag.svg');
      }

      &.dynamic-node-width {
        .node, .external-source-node {
          width: unset;
          min-width: 180px;
          max-width: 400px;
        }

        .node {
          z-index: 20;
        }
      }

      .external-source-node {
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        margin: 0;
        padding: 3px;
        border: 2px dashed transparent;
        text-align: center;
        width: 130px;
        z-index: 5;

        .title {
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          height: 20px;
          line-height: 20px;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          white-space: nowrap;
          background-color: rgba(217, 83, 79, .8);
          color: #fff;
          border-radius: 4px 4px 0 0;
        }

        .content {
          box-sizing: border-box;
          width: 100%;
          height: 20px;
          font-size: 11px;
          line-height: 18px;
          border: 1px solid rgba(217, 83, 79, .8);
          border-radius: 0 0 4px 4px;
          text-align: center;
          background-color: #fff;
          color: #333;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .node, .external-source-node {
        margin: 4px;
        padding: 0;
        border: unset;
        box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
        transition: unset;
        box-sizing: content-box;
        border: 1px transparent solid;

        .title {
          height: 32px;
          padding-left: 6px;
          display: flex;
          align-items: center;
          border-radius: unset !important;
          transition: 0.5s;

          &.offline {
            background-color: #d0cece !important;
          }

          .symbol {
            width: 0;
            margin: 0;
          }

          .node-icon {
            background-repeat: no-repeat !important;
            background-size: contain !important;
            background-position: center !important;
            height: 100%;
            width: 25px;
          }

          .title-text {
            padding: 0 6px;
            width: fit-content;
          }
        }

        &.focused {
          margin: 3px;
          border-width: 2px !important;
        }

        &.allowedDrop {
          border: 2px dashed #5bd037 !important;
          margin: 2px;

          &.node.focused {
            background-color: rgba(0, 0, 0, 0);
          }
        }

        &.draft {
          .title {
            background-color: #d0cece;

            .node-icon {
              display: none;
            }
          }
        }

        &.ROOT {
          height: fit-content;

          &.focused {
            border: 1px solid $grid-node-color;
            box-shadow: 0 3px 14px 0 $grid-node-color;
          }

          .title {
            background-color: $grid-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Root_Node.svg');
            }
          }
        }

        &.RENEWABLE {
          height: fit-content;

          &.focused {
            border: 1px solid $renewable-node-color;
            box-shadow: 0 3px 14px 0 $renewable-node-color;
          }

          .title {
            background-color: $renewable-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Renewable_energy.svg');
            }
          }
        }

        &.STORAGE {
          height: fit-content;

          &.focused {
            border: 1px solid $storage-node-color;
            box-shadow: 0 3px 14px 0 $storage-node-color;
          }

          .title {
            background-color: $storage-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Storage.svg');
            }
          }
        }

        &.RENEWABLE_WITH_STORAGE {
          height: fit-content;

          &.focused {
            border: 1px solid $renewable-with-storage-node-color;
            box-shadow: 0 3px 14px 0 $renewable-with-storage-node-color;
          }

          .title {
            background-color: $renewable-with-storage-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Combined_source.svg');
            }
          }
        }

        &.LOAD {
          &.focused {
            border: 1px solid $load-node-color;
            box-shadow: 0 3px 14px 0 $load-node-color;
          }

          .title {
            background-color: $load-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Load_Node.svg');
            }
          }
        }

        &.MEASUREMENT_POINT {
          &.focused {
            border: 1px solid $measurer-node-color;
            box-shadow: 0 3px 14px 0 $measurer-node-color;
          }

          .title {
            background-color: $measurer-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Meter_Node.svg');
            }
          }
        }

        &.CHARGER {
          &.focused {
            border: 1px solid $charger-node-color;
            box-shadow: 0 3px 14px 0 $charger-node-color;
          }

          .title {
            background-color: $charger-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Charger_Node.svg');
            }
          }
        }

        &.CONSTRAINT {
          &.focused {
            border: 1px solid $hard-constraint-node-color;
            box-shadow: 0 3px 14px 0 $hard-constraint-node-color;
          }

          .title {
            background-color: $hard-constraint-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Constraint_Node.svg');
            }
          }
        }

        &.SOFT_CONSTRAINT {
          &.focused {
            border: 1px solid $soft-constraint-node-color;
            box-shadow: 0 3px 14px 0 $soft-constraint-node-color;
          }

          .title {
            background-color: $soft-constraint-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Constraint_Node.svg');
            }
          }
        }

        &.CHARGING_POLICY {
          &.focused {
            border: 1px solid $charging-policy-node-color;
            box-shadow: 0 3px 14px 0 $charging-policy-node-color;
          }

          .title {
            background-color: $charging-policy-node-color;

            .node-icon {
              background: url('/screens/PlazaView/resources/Policy_Node.svg');
            }
          }
        }

        &.JUNCTION {
          width: 40px;
          min-width: 40px;
          margin: 4px 70px;
          border-width: 1px;

          &.focused {
            margin: 4px 70px;
            border: 1px solid $junction-node-color !important;
            box-shadow: 0 3px 14px 0 $junction-node-color;
          }

          .title {
            background-color: $junction-node-color;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .node-icon {
              background: url('/screens/PlazaView/resources/Junction.svg');
            }
          }
        }

        .content {
          padding: 0 0 12px;
          height: unset;
          word-wrap: break-word;
          text-overflow: unset;
          white-space: unset;
          border: unset;
          border-radius: unset !important;
          transition: max-height 1s, all .5s;
          max-height: 700px;

          .external-source-info-container {
            padding: 4px 8px;
            background-color: #f2f2f2;
            display: flex;
            flex-direction: row;

            .external-source-info-icon {
              background-repeat: no-repeat !important;
              background-size: contain !important;
              background-position: center !important;
              height: 17px;
              width: 17px;

              &:not(:nth-last-child(1)) {
                margin-right: 5px;
              }

              &.withMeasurementPoint {
                background: url('/screens/PlazaView/resources/Meter_Node_info_icon.svg');
              }

              &.discharging {
                background: url('/screens/PlazaView/resources/Gray_Storage.svg');
              }

              &.withHardConstraint {
                background: url('/screens/PlazaView/resources/Constraint_Node_info_icon.svg');
              }
            }
          }

          .dynamic-data {
            background-color: #2222220f;
            margin: 7px 7px 0 7px;
          }

          .content-line {
            display: flex;
            flex-direction: row;
            padding-left: 8px;
            padding-right: 8px;

            .label {
              color: gray;
              padding-right: 3px;
              white-space: nowrap;
            }

            .value {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font-weight: bold;
            }
          }
        }
      }

      .leftEdge, .rightEdge, .topEdge {
        height: 1px;
        width: 1px;
      }

      .add-button {
        height: 30px;
        width: 30px;
        background: url('/screens/PlazaView/resources/add_button.svg');
        background-size: cover;
        display: none;
        cursor: pointer;
        position: absolute;
        left: calc(50% - 15px);
        bottom: -17px;

        &.left {
          top: calc(50% - 15px);
          left: -17px;
        }

        &.right {
          top: calc(50% - 15px);
          left: calc(100% - 13px);
        }

        &.hovered-node {
          display: inline-block;
        }
      }

      .delete-button {
        height: 20px;
        width: 20px;
        background-image: url('/screens/PlazaView/resources/close_button.svg');
        background-size: cover;
        display: none;
        position: absolute;
        top: -6px;
        right: -6px;
        cursor: pointer;

        &.hovered-node {
          display: inline-block;
        }
      }
    }
  }

  .topology-tooltip {
    background-color: $tooltipBackgroundColor;
    padding: 5px;
    line-height: 17px;
    border-radius: 4px;
    font-weight: 400;
    font-family: Roboto;

    transition: 0.1s;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);

    .thick {
      font-weight: 600;
    }
  }

  .topology-phases {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -130%);
    background-color: gray;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
    height: 16px;
    z-index: 5;

    .topology-phases-container {
      display: flex;

      &:hover {
        .topology-tooltip {
          visibility: visible;
          opacity: 1;
        }
      }


      .topology-phases-element {
        line-height: 17px;
        color: black;
        font-family: Roboto;
        background-color: white;
        cursor: pointer;
        height: 16px;
        width: 16px;

        &:not(:nth-last-child(2)) {
          border-right: 1px solid white;
        }

        &.active {
          background-color: #cdd9ff;
        }

        &.first {
          background-color: #cde2b9;
        }

        &.second {
          background-color: #c6e5f1;
        }

        &.third {
          background-color: #ffe9b6;
        }

      }
    }
  }
}

.editor-form {
  grid-column: 2;
  grid-row: 1;
  padding: 10px;
  z-index: 1; //TODO not a great way to save box-shadow
  box-shadow: 0 0 28px rgba(0, 45, 61, 0.08);
  position: relative;
  width: 260px;
  flex-flow: column wrap;
  float: left;
  -ms-flex-align: start;
  align-items: start;

  //@media #{$tablet-and-phone} {
  //  width: unset;
  //  box-shadow: unset;
  //
  //  //height: calc(100dvh - 182px);
  //  overflow-y: scroll;
  //}

  .chart-select-field.clearable {
    width: 208px !important;
  }

  .autocompleteWrapper {
    width: 257px;
    margin-right: 10px;
    height: 64px;
  }

  .filter-item {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .DateRangePickerInput {
      width: 248px;
    }
  }

  & .MuiSelect-select.MuiSelect-selectMenu {
    padding: 7px 24px 7px 0;

    &:focus {
      background-color: transparent;
    }
  }

  .MuiFormControl-root {
    border-radius: 4px;
    transition: all .2s ease;
    padding: 8px 10px 8px 17px !important;
    box-sizing: border-box;

    &:hover {
      box-shadow: 0 0 20px rgba(0, 45, 61, 0.08);
    }

    .MuiInputLabel-formControl {
      top: 8px;
      left: 17px;
    }
  }

  .compact {
    .MuiSelect-select {
      padding: 1px 24px 2px 0;
      font-size: 14px;
    }
  }

  .date-picker-container.compact {
    top: -10px !important;
    margin-top: -8px;
  }
}

$dynamic-icon-width: 20px;

@mixin icon-error {
  border: 2px solid $error-color;
  &:after {
    font-family: Roboto;
    content: '!';
    background-color: $error-color;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    position: absolute;
    -ms-transform: translate(10px, 10px);
    transform: translate(13px, 13px);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
  }
}

.dyncamic-container {
  margin-bottom: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
}

$animation-duration: 0.5s;
.dynamic-icon {
  width: $dynamic-icon-width;
  height: $dynamic-icon-width;
  display: flex;
  border-radius: 50%;
  animation-fill-mode: forwards;
  animation-direction: normal;
  background-color: white;
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 3;

  &.charging, &.chargingWithoutFinished {
    background-image: url('/screens/PlazaView/resources/new/full_charge.svg');
  }

  &.discharging {
    background-size: 14px;
    background-image: url('/screens/PlazaView/resources/discharging.svg');
  }

  &.charging, &.chargingWithoutFinished, &.discharging {
    &.active {
      border: 2px solid rgb(155, 211, 109);
      // animation: appear $animation-duration ease-in-out 1;
      animation-fill-mode: forwards, forwards;

      &.preload {
        width: $dynamic-icon-width;
        opacity: 1;
        // transform: translateY(0px);
      }
    }
  }

  &.chargingError {
    &.active {
      border: 2px solid $error-color;

      &.error {
        @include icon-error;
      }
    }

    background-image: url('/screens/PlazaView/resources/new/full_charge.svg');
  }

  &.dischargingError {
    &.active {
      border: 2px solid $error-color;

      &.error {
        @include icon-error;
      }
    }

    background-image: url('/screens/PlazaView/resources/discharging.svg');
    background-size: 14px;
  }

  &.authorizationRequested {
    background-image: url('/screens/PlazaView/resources/new/request.svg');
    background-size: 16px;

    &.active {
      border: 2px solid #fdc564;
    }
  }

  &.evConnected {
    background-image: url('/screens/PlazaView/resources/new/car.svg');
    background-size: 16px;

    &.active {
      border: 2px solid #fdc564;
    }
  }

  &.evDisconnected {
    background-image: url('/screens/PlazaView/resources/power_off_black_24dp.svg');
    background-size: 16px;

    &.active {
      border: 2px solid #fdc564;
    }
  }

  &.applyingLimit {
    background-image: url('/screens/PlazaView/resources/new/answ.svg');
    background-size: 16px;

    &.active {
      border: 2px solid rgb(155, 211, 109);
    }
  }

  &.online {
    background-image: url('/screens/PlazaView/resources/lost_connection.svg');
    background-size: 16px;

    &.active {
      border: 2px solid $error-color;

      &.error {
        @include icon-error;
      }
    }
  }

  &.sessionFinished {
    background-image: url('/screens/PlazaView/resources/session_finished.svg');

    &.active {
      border: 2px solid rgb(155, 211, 109);
      animation: appear $animation-duration ease-in-out 1;
      animation-fill-mode: forwards;

      &.error {
        @include icon-error;
      }
    }
  }

  &.active {
    margin: 0 2px 0 2px;
    // animation: appear $animation-duration ease-in-out 1;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }

  &.inactive {
    // animation: disappear $animation-duration ease-in-out 1;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }

  &.preload {
    animation-delay: -($animation-duration) !important;
    animation-duration: 0.00001s !important;
    // to make animation fired in Safari (with animation-duration: 0s it's not played at all)
  }
}

@keyframes appear {
  0% {
    width: 0;
    opacity: 0;
    border: unset;
    transform: translateY(15px);
  }
  50% {
    width: $dynamic-icon-width;
    opacity: 0;
    border: unset;
    transform: translateY(15px);
  }
  75% {
    opacity: 0.5;
  }
  100% {
    width: $dynamic-icon-width;
    opacity: 1;
    transform: translateY(0px);
    //border: 2px solid rgb(155, 211, 109); // optional

  }
}

@keyframes disappear {
  0% {
    width: $dynamic-icon-width;
    opacity: 1;
    transform: translateY(0px);
    //border: 2px solid rgb(155, 211, 109); // optional
  }
  25% {
    opacity: 0.5;
  }
  50% {
    width: $dynamic-icon-width;
    opacity: 0;
    border: unset;
    transform: translateY(15px);
  }
  100% {
    width: 0;
    opacity: 0;
    border: unset;
    transform: translateY(15px);
    margin: unset;
  }
}

@keyframes pulse { // no transform
  50% {
    border-color: rgb(255, 183, 15);
  }
}

@keyframes charging-pulse { // no transform
  50% {
    //background-color: rgb(192, 223, 255);
    border-color: rgb(255, 183, 15);
  }
}

.charger-list-item-name {
  position: absolute;
  top: 0;
  font-size: 12px !important;
}

.node-select-form, .log-events {
  .center-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  .stub-label {
    font-family: "Roboto";
    opacity: 0.3;
    color: #101213;
    font-size: 18px !important;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
  }

  .no-children-label {
    border-bottom: none !important;
    margin: 0 auto;
    font-weight: unset;
    font-size: 13px !important;
    padding: 9px 0 !important;
  }
}

.chargers-autocomplete-tooltip {
  word-wrap: break-word;
  width: auto;
  max-width: 190px;
  font-size: 13px;
  font-family: Roboto;
}

.inactive-charger-icon {
  background-color: white;
  width: 18px;
  height: 18px;
  border: 2px solid #ffcb00;
  border-radius: 50%;
  color: #ffcb00;
  line-height: 20px;
  -ms-flex: none;
  flex: none;
  padding-left: .2px;
  font-size: 18px;
  font-weight: 700;
  left: -7px;
  top: -7px;

  &.legend {
    position: relative;
    text-align: center;
    top: -8px;
    left: 0;

    .inactive-tooltip {
      top: -6px;
      left: -155px;
    }

    &:hover {
      .inactive-tooltip {
        visibility: visible;
        opacity: 1;
        left: -155px;
        top: -6px;
      }
    }
  }

  .inactive-tooltip {
    transition: 0.1s;
    background-color: $tooltipBackgroundColor !important;
    line-height: 17px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto;
    color: white;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: 3px 7px;
    top: 0;
    left: 20px;
    margin-top: 12px;

    .thick {
      font-weight: 600;
    }
  }

  &:hover {
    .inactive-tooltip {
      visibility: visible;
      opacity: 1;

      left: 20px;
    }
  }
}

.plaza-topology-settings-component {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 30px;
  right: 35px;
  display: flex;
  flex-direction: column;
  z-index: 90;

  .settings-item {
    &:not(:nth-last-child(1)) {
      margin-bottom: 5px;
    }
  }

  .plaza-topology-popover-trigger {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    color: #a2a2a2;
    cursor: pointer;
    position: relative;
    z-index: 0;

    &.active {
      z-index: unset;
    }

    .plaza-topology-popover-content {
      text-align: initial;
      font-family: Roboto;
      color: black;
      visibility: hidden;
      opacity: 0;
      background-color: #ffffff;
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
      padding: 10px;
      transition: 0.2s;
      display: flex;
      flex-direction: column;
      position: absolute;
      width: max-content;
      right: -8px;
      top: -3px;
      z-index: 1;

      & > * {
        display: flex;
        margin: 3px 0;
        flex-direction: row;
        align-items: center;

        .dynamic-icon {
          margin-right: 10px;

          &.sessionFinished {
            &.active {
              animation: appear 0.2s ease-in-out 1;
              animation-fill-mode: forwards;
            }
          }
        }
      }

      &.active {
        visibility: visible;
        opacity: 1;
        right: -5px;
      }

      .energy-legend {
        position: relative;
        margin: 0 33px 0 3px;

        .energy-connector.horizontal.right {
          width: 25px;
          height: 4px;
          left: 0;
          top: -2px;
          opacity: 1;
          display: block;
        }
      }
    }
  }
}

.saved-money {
  position: absolute;
  user-select: none;
  animation: saved-money-animation-header 5500ms linear infinite;

  box-sizing: border-box;
  top: 200px;
  right: 40px;
  height: 283px;
  border: 4px solid #FEE1B0;
  border-radius: 2px;
  background-color: #FDC461;
  background-size: 300% 100%;
  background-image: linear-gradient(
                  to right,
                  transparent 33%,
                  rgba(255, 255, 255, 0.3) 50%,
                  transparent 66%
  );

  .icon {
    position: absolute;
    top: -40px;
    box-sizing: border-box;
    height: 80px;
    width: 80px;
    border: 4px solid #FEE1B0;
    background-color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-radius: 40px;
    animation: bulb-animation 5000ms linear infinite;

    @keyframes bulb-animation {
      0% {
        box-shadow: 0 0 0 0 #fdc35f99;
      }

      92% {
        box-shadow: 0 0 0 0 #fdc35f99;
      }

      98% {
        box-shadow: 0 0 25px 40px #fdc35f99;
      }

      100% {
        box-shadow: 0 0 25px 120px transparent;
      }
    }

    .bulb {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 36px 53px;
      background-image: url('/screens/PlazaView/resources/Bulb-on.svg');
    }
  }

  .header {
    height: 25px;
    color: #FFFFFF;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    padding: 58px 0 10px 0;
  }

  .content {
    padding: 6px 15px;
    font-family: "Open Sans", sans-serif;

    .row {
      padding-top: 3px;
      padding-bottom: 18px;

      .label {
        height: 19px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.08px;
        line-height: 19px;
        padding-bottom: 10px;
      }

      .value-block {
        display: flex;
        justify-content: flex-end;
        font-size: 22px;

        div:not(:last-child) {
          margin-right: 7px;
        }

        .separator {
          color: #FFFFFF;
          display: flex;
          align-self: flex-end;
        }

        .currency {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;
          padding-bottom: 3px;
          font-weight: 700;
        }

        .number {
          height: 36px;
          width: 32px;
          border-radius: 2px;
          background-color: #FFFFFF;
          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 3px;
          overflow: hidden;
          position: relative;

          .digits {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 4px;
            transition: top ease-in-out 0.5s;

            .digit {
              color: #101213;
              font-weight: 600;
              line-height: 30px;
              text-align: center;
            }
          }


        }
      }
    }
  }

  @keyframes saved-money-animation-header {
    0% {
      background-position: right;
    }

    90% {
      background-position: right;
    }

    100% {
      background-position: left;
    }
  }

  @keyframes saved-money-animation {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
  }
}

.editor-form-error {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $error-color;
  position: absolute;
  text-align: center;
  left: 0;
  bottom: 50px;
  overflow-wrap: normal;
  word-break: break-word;
  background-color: #ffb2b2;
  margin: 14px;
  padding: 5px;
  border-radius: 2px;
}

.source-nodes-tr-wrap {
  display: table-caption;
}

.external-source-node {
  height: max-content;
}

.source-nodes-container {
  display: flex;
  justify-content: center;
  background-color: #8080802b;
  box-shadow: 0px -1000px 0px 1015px #80808029;

  .root-node-wrapper {
    margin: 0 10px;
  }

  .external-source-node {
    //&.RENEWABLE,&.RENEWABLE_WITH_STORAGE,&.STORAGE {
    //  min-width: unset !important;
    //}
  }

  .external-source {
    display: flex;
    min-width: 100px;
    min-height: 2px;
    width: fit-content;

    &.left {
      justify-content: flex-end;
    }

    &.right {
      justify-content: flex-start;
    }
  }
}

.charger-name-id-field-group {
  padding: 12px;
  margin: -11px;
  margin-bottom: 4px;

  .name-expand-container {
    transition: .2s;
    max-height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;

    &.expand {
      max-height: 137px;
    }
  }
}

.MuiFormHelperText-root {
  &.Mui-error {
    width: 100%;
    margin-top: 2px;
  }
}

.edit-node-form {
  .MuiDialog-paperWidthSm {
    max-width: 640px;
  }
}

.editor-legend-icon-wrapper {
  padding: 5px;
  margin-right: 10px;
  border-radius: 50%;
}

.editor-legend-icon {
  width: 16px;
  height: 16px;
}

.editor-legend-grid-icon-wrapper {
  background-color: $grid-node-color;
}

.editor-legend-grid-icon {
  background: url('/screens/PlazaView/resources/Root_Node.svg') no-repeat center/contain;
}

.editor-legend-charging-policy-icon-wrapper {
  background-color: $charging-policy-node-color;
}

.editor-legend-charging-policy-icon {
  background: url('/screens/PlazaView/resources/Policy_Node.svg') no-repeat center/contain;
}

.editor-legend-renewable-icon-wrapper {
  background-color: $renewable-node-color;
}

.editor-legend-renewable-icon {
  background: url('/screens/PlazaView/resources/Renewable_energy.svg') no-repeat center/contain;
}

.editor-legend-renewable-with-storage-icon-wrapper {
  background-color: $renewable-with-storage-node-color;
}

.editor-legend-renewable-with-storage-icon {
  background: url('/screens/PlazaView/resources/Combined_source.svg') no-repeat center/contain;
}

.editor-legend-storage-icon-wrapper {
  background-color: $storage-node-color;
}

.editor-legend-storage-icon {
  background: url('/screens/PlazaView/resources/Storage.svg') no-repeat center/contain;
}

.editor-legend-hard-constraint-icon-wrapper {
  background-color: $hard-constraint-node-color;
}

.editor-legend-soft-constraint-icon-wrapper {
  background-color: $soft-constraint-node-color;
}

.editor-legend-hard-constraint-icon, .editor-legend-soft-constraint-icon {
  background: url('/screens/PlazaView/resources/Constraint_Node.svg') no-repeat center/contain;
}

.editor-legend-junction-icon-wrapper {
  background-color: $junction-node-color;
}

.editor-legend-junction-icon {
  background: url('/screens/PlazaView/resources/Junction.svg') no-repeat center/contain;
}

.editor-legend-load-icon-wrapper {
  background-color: $load-node-color;
}

.editor-legend-load-icon {
  background: url('/screens/PlazaView/resources/Load_Node.svg') no-repeat center/contain;
}

.editor-legend-measurer-icon-wrapper {
  background-color: $measurer-node-color;
}

.editor-legend-measurer-icon {
  background: url('/screens/PlazaView/resources/Meter_Node.svg') no-repeat center/contain;
}

.editor-legend-charger-icon-wrapper {
  background-color: $charger-node-color;
}

.editor-legend-charger-icon {
  background: url('/screens/PlazaView/resources/Charger_Node.svg') no-repeat center/contain;
}
