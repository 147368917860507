.plaza-form-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 8px;

  @media #{$mobile-only} {
    margin-top: 20px;
    position: relative;
  }

  .plaza-form-submit-button {
    width: 125px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.active-chargers-field {
  margin-bottom: 5px;
  width: 100%;

  & .MuiFormControlLabel-label {
    font-size: 14px;
    margin-left: 5px;
    line-height: 140%;
  }
}
