@keyframes dash {
  to {
    stroke-dashoffset: -10;
  }
}

@keyframes reverse-dash {
  to {
    stroke-dashoffset: 10;
  }
}

.plaza-form {
  padding: 20px;
  display: flex;
  margin-bottom: 50px;
  flex-direction: column;

  .row {
    flex-direction: row;

    &.error {
      width: 542px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: $error-color;
      height: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-device-width: 1224px) {
        width: 100%
      }
    }

    .column {
      width: 256px !important;
      flex-direction: column;
      margin-right: 30px;
      float: left;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;

    .submit-button {
      width: 256px !important;
      margin-right: 30px;
    }
  }

  &.loading {
    filter: blur(4px);
    -webkit-filter: blur(4px);
    pointer-events: none
  }

  .plaza-details-snackbar {
    bottom: 9dvh !important;
    text-align: center;
  }
}

.plaza-status-field > div > div {
  cursor: auto !important;
}

.plaza-details-form {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .flex-column {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    padding: 8px;
  }

  .messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 10px;
    padding-bottom: 10px;
  }
}

.plaza-details-form-error {
  text-align: left;
  font-weight: 500;
  color: $error-color;
}

.plaza-title {
  font-weight: 700;

  @media #{$mobile-only} {
    font-size: 0.9em;
  }
}