$level1: rgb(253, 196, 97);
$level2: rgb(114, 188, 196);
$level3: rgb(114, 143, 195);

$levelColors: $level1, $level2, $level3;

.query-builder {
  position: relative;
  font-family: "Roboto", sans-serif;

  .ruleGroup {
    position: relative;
    padding-top: 20px;
  }

  .ruleGroup::after {
    content: " ";
    height: 48px;
    display: block;
    margin-left: 25px;
    border-left-width: 2px;
    border-left-style: dashed;
  }

  .ruleGroup .ruleGroup::after {
    margin-left: 100px;
  }

  .rule {
    display: flex;
    margin-left: 25px;
    padding-top: 20px;

    .MuiInputBase-root {
      height: 100%;
    }
  }

  .ruleGroup .ruleGroup .rule {
    margin-left: 100px;
  }

  .ruleGroup .ruleGroup {
    margin-left: 25px;

    .query-builder-add-rule-btn {
      left: 100px;
    }

    .query-builder-add-group-btn {
      left: 315px;
    }
  }

  .ruleGroup .ruleGroup .ruleGroup {
    margin-left: 100px;
  }

  .ruleGroup .ruleGroup, .rule {
    position: relative;
    border-left-width: 2px;
    border-left-style: solid;
  }

  .query-builder-combinator-selector,
  .query-builder-field-selector {
    display: inline-flex;
    align-items: center;
    height: 33px;
  }

  .query-builder-field-selector,
  .query-builder-operator-selector {
    margin-right: 10px;
  }

  .query-builder-offset-line {
    width: 25px;
    height: 1px;
    border-top: 2px solid rgba(0, 0, 0, 0.42);
  }

  .query-builder-combinator-indicator {
    margin: 0 10px;
  }

  .query-builder-combinator-indicator {
    text-transform: capitalize;
    width: 30px;
    text-align: center;
  }

  .query-builder-combinator-text {
    text-transform: lowercase;
    margin: 0 40px 0 10px;
    white-space: nowrap;
  }

  .query-builder-add-group-btn {
    left: 240px;
  }

  .query-builder-add-rule-btn {
    display: flex;
    align-items: center;
    left: 25px;

    .query-builder-offset-line {
      margin-right: 20px;
      border-top-style: dashed;
    }
  }

  .query-builder-add-group-btn,
  .query-builder-add-rule-btn {
    position: absolute;
    bottom: -16px;
  }

  .query-builder-remove-group-btn {
    margin-left: -31px;
    margin-top: 5px;
    padding: 0;
    color: #fff;

    &.MuiIconButton-root:hover {
      background-color: unset;
    }
  }

  .query-builder-remove-rule-btn {
    height: 33px;
    width: 33px;
  }

  .combinator-selector-container {
    .MuiSelect-select, .MuiSelect-icon {
      color: #fafafa;
    }

    .MuiInput-underline {
      &:before {
        display: none;
      }
    }
  }

  .value-editor-date {
    width: 250px;
    margin-right: 10px;

    button {
      padding: unset;
    }

    .MuiInput-root {
      height: 33px;
    }
  }
}

.advanced-search-query-bilder-container {
  margin: -8px -24px 0px -8px;
  padding: 0 24px;
  overflow-y: auto;
  max-height: 95%;
  min-height: 50dvh;

  > .query-builder {
    margin-bottom: 25px;
  }
}

.query-builder-operator-indicator {
  display: inline-flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}

@mixin rule-group-child($depth, $colors, $count: 1) {
  border-left-color: nth($colors, $count);

  @if $count < length($colors) {
    &:after {
      border-left-color: nth($colors, $count + 1);
    }
  } @else {
    &:after {
      border-left-color: nth($colors, 1);
    }
  }

  .ruleGroup {
    @if $depth > 0 {
      @if length($colors) <= $count {
        @include rule-group-child($depth, $colors, 1)
      } @else {
        @include rule-group-child($depth - 1, $colors, $count + 1)
      }
    }
  }
}

.ruleGroup {
  .ruleGroup {
    @include rule-group-child(10, $levelColors);
  }

  &:after {
    border-left-color: nth($levelColors, 1);
  }
}

@mixin rule-child($depth, $colors, $count: 1) {
  @if $depth > 0 {
    .ruleGroup {
      @if length($colors) == $count {
        @include rule-child($depth - 1, $colors, 1)
      } @else {
        @include rule-child($depth - 1, $colors, $count + 1)
      }
    }
  } @else {
    .rule {
      border-left-color: nth($colors, $count);
    }
  }
}

@for $i from 0 through 10 {
  .ruleGroup {
    @include rule-child($i, $levelColors);
  }
}
